import React, { useEffect, useState } from 'react'
import { Button, Form, type FormProps, Modal, Select } from 'antd'

import { ProductService } from '@services/ProductService'
import { BundleService } from '@services/BundleService'
import { useNotifications } from '@store/notifications'

import type { Bundle, Product } from '../../types'

type ModalProps = {
    isOpen: boolean
    product: Product
    onClose: () => void
    refetch: () => Promise<void>
}

export const AddBundleModal: React.FC<ModalProps> = ({ isOpen, onClose, product, refetch }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [bundles, setBundles] = useState<Bundle[]>([])
    const [form] = Form.useForm()
    const { showNotification } = useNotifications()

    useEffect(() => {
        const fetchBundles = async () => {
            const b = await BundleService.list()

            setBundles(b.filter(bund => !product.productBundles?.some(pb => pb.bundleId === bund.id)))
        }
        fetchBundles()
    }, [isOpen])

    const onConfirm: FormProps<any>['onFinish'] = async values => {
        try {
            setIsLoading(true)
            await ProductService.addBundle(product.id, values.bundle)

            await refetch()
            form.resetFields()
            onClose()
        } catch (err: any) {
            showNotification({
                type: 'error',
                title: 'Failed to add bundle',
                description: err.toString()
            })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                onFinish={onConfirm}
            >
                <h3>Add Bundle</h3>
                <Form.Item label="Bundle" name="bundle">
                    <Select
                        style={{ minWidth: '300px' }}
                        placeholder="Select a bundle"
                        options={bundles.map(b => ({
                            label: b.name,
                            value: b.id,
                            color: 'green'
                        }))}
                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
                        Add
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}
