import { httpClient } from '@connectors/HttpClient'

import { type Objective } from '../types'

export type ObjectiveParams = Omit<Objective, 'id'>

export const ObjectiveService = {
    list: async (): Promise<any> => {
        const response = await httpClient.axios.get('/admin/objectives')
        return response.data
    },
    get: async (id: string): Promise<any> => {
        const response = await httpClient.axios.get(`/admin/objectives/${id}`)
        return response.data
    },
    update: async (id: string, params: Partial<ObjectiveParams>): Promise<any> => {
        const response = await httpClient.axios.put(`/admin/objectives/${id}`, params)
        return response.data
    },
    create: async (params: ObjectiveParams): Promise<any> => {
        const response = await httpClient.axios.post('/admin/objectives', params)
        return response.data
    },
    delete: async (id: string): Promise<any> => {
        const response = await httpClient.axios.delete(`/admin/objectives/${id}`)
        return response.data
    }
}
