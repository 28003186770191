import React, { useEffect, useState } from 'react'
import { Button, type FormProps, Modal } from 'antd'

import { useNotifications } from '@store/notifications'

import { type ObjectiveParams, ObjectiveService } from '@services/ObjectiveService'

import { useConfirmation } from '@store/confirmation'
import { type Objective } from '../../../../types'
import { ObjectiveForm } from '../components/ObjectiveForm'

type ModalProps = {
    curriculumId: string
    selectedId: string
    onClose: () => void
    onSuccess: () => void
}

export const EditObjectiveModal: React.FC<ModalProps> = ({ selectedId, curriculumId, onClose, onSuccess }) => {
    const [data, setData] = useState<Objective>()
    const { showNotification } = useNotifications()
    const confirm = useConfirmation()

    useEffect(() => {
        const fetch = async () => {
            try {
                const results = await ObjectiveService.get(selectedId!)
                setData(results)
            } catch (err: any) {
                showNotification({ title: 'Could not retrieve curriculum', type: 'error', description: err.toString() })
            }
        }

        if (selectedId) {
            fetch()
        }
    }, [selectedId])

    const onFinish: FormProps<Objective>['onFinish'] = async values => {
        const body: ObjectiveParams = {
            description: values.description,
            objectiveCategoryId: values.objectiveCategoryId,
            yearLevelId: values.yearLevelId,
            code: values.code,
            order: values.order
        }

        try {
            await ObjectiveService.update(selectedId, body)
            showNotification({ title: 'Objective updated successfully' })
            onSuccess()
            onClose()
        } catch (err: any) {
            showNotification({ title: 'Objective update failed', type: 'error', description: err.toString() })
        }
    }

    const onDelete = async () => {
        if (
            await confirm({
                title: 'Discarding draft',
                description: 'Are you sure you want to remove this draft?',
                confirmType: 'danger'
            })
        ) {
            try {
                await ObjectiveService.delete(selectedId)
                showNotification({ title: 'Objective has been deleted successfully' })

                onSuccess()
                onClose()
            } catch (err: any) {
                showNotification({
                    title: 'Objective deletion failed',
                    type: 'error',
                    description: err.toString()
                })
            }
        }
    }

    const isOpen = !!selectedId

    if (!data) {
        return null
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            <ObjectiveForm
                title="Edit Objective"
                initialValues={data}
                onFinish={onFinish}
                onCancel={onClose}
                objectiveCategoryId={data.objectiveCategoryId}
                curriculumId={curriculumId}
            />
            <Button type="link" onClick={onDelete}>
                Delete
            </Button>
        </Modal>
    )
}
