import { notification } from 'antd'

import { useNotifications } from '@store/notifications'
import { useEffect } from 'react'

export const Notifications = () => {
    const { key, title, description, type, placement, duration } = useNotifications()

    const [api, contextHolder] = notification.useNotification()

    useEffect(() => {
        if (key) {
            api[type]({
                message: title,
                description,
                placement,
                duration
            })
        }
    }, [key])

    return <>{contextHolder}</>
}
