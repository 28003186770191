import { Flex } from 'antd'

const flexStyle: React.CSSProperties = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 22
}

const PageLoader = () => {
    return <Flex style={flexStyle}>Loading</Flex>
}

export default PageLoader
