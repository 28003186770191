import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Modal, Form, DatePicker, Button, Select } from 'antd'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'

import { Product, User, subscriptionsStatuses, subscriptionTerms } from '../../../../types'
import { UserService } from '@services/UserService'

dayjs.extend(weekday)
dayjs.extend(localeData)

interface AddSubscriptionModalProps {
    isOpen: boolean
    onClose: () => void
    user: User
    products: Product[]
    refetch: () => Promise<void>
}

const AddSubscriptionModal: React.FC<AddSubscriptionModalProps> = ({ isOpen, onClose, user, products, refetch }) => {
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (values: any) => {
        const formattedValues = {
            startDate: values.dateRange[0].toISOString(),
            endDate: values.dateRange[1].toISOString(),
            userId: user.id,
            productIds: values.productIds || [],
            status: values.status,
            term: values.term
        }
        try {
            setIsLoading(true)
            await UserService.addSubscription(user.id, formattedValues)
            onClose()
            await refetch()
            form.resetFields()
        } catch (error) {
            console.error('Error adding subscription:', error)
        } finally {
            setIsLoading(false)
        }
    }

    const today = dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
    const oneYearFromToday = today.add(1, 'year')

    return (
        <Modal title="Add Subscription" open={isOpen} onCancel={onClose} footer={null}>
            <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={{ status: 'ENDING' }}>
                <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Please select a status' }]}>
                    <Select>
                        {Object.values(subscriptionsStatuses).map(status => (
                            <Select.Option key={status} value={status}>
                                {status}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="term"
                    label="Term"
                    rules={[{ required: true, message: 'Please select a term' }]}
                    initialValue="YEARLY"
                >
                    <Select>
                        {Object.values(subscriptionTerms).map(term => (
                            <Select.Option key={term} value={term}>
                                {term}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="dateRange"
                    label="Start & End Date"
                    rules={[{ required: true, message: 'Please select a start and end date' }]}
                    initialValue={[today, oneYearFromToday]}
                >
                    <DatePicker.RangePicker />
                </Form.Item>

                <Form.Item name="productIds" label="Products">
                    <Select
                        mode="multiple"
                        options={products.map(product => ({ label: product.name, value: product.id }))}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
                        Add Subscription
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddSubscriptionModal
