import React, { useEffect, useState } from 'react'
import { type FormProps, Modal } from 'antd'

import { useNotifications } from '@store/notifications'

import { type CurriculumParams, CurriculumService } from '@services/CurriculumService'

import { type Curriculum } from '../../../../types'
import { type CurriculumFieldType, CurriculumForm } from '../components/CurriculumForm'

type ModalProps = {
    selectedId: string | null
    onClose: () => void
    onSuccess: () => void
}

export const EditCurriculumModal: React.FC<ModalProps> = ({ selectedId, onClose, onSuccess }) => {
    const [data, setData] = useState<Curriculum>()
    const { showNotification } = useNotifications()

    useEffect(() => {
        const fetch = async () => {
            try {
                const results = await CurriculumService.get(selectedId!)
                setData(results)
            } catch (err: any) {
                showNotification({ title: 'Could not retrieve curriculum', type: 'error', description: err.toString() })
            }
        }

        if (selectedId) {
            fetch()
        }
    }, [selectedId])

    const onFinish: FormProps<CurriculumFieldType>['onFinish'] = async values => {
        const body: CurriculumParams = {
            name: values.name,
            slug: values.slug,
            shortCode: values.shortCode
        }

        try {
            await CurriculumService.update(selectedId!, body)
            showNotification({ title: 'Curriculum updated successfully' })
            onSuccess()
            onClose()
        } catch (err: any) {
            showNotification({ title: 'Curriculum update failed', type: 'error', description: err.toString() })
        }
    }

    const isOpen = !!selectedId

    if (!data) {
        return null
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            <CurriculumForm title="Edit Curriculum" initialValues={data} onFinish={onFinish} onCancel={onClose} />
        </Modal>
    )
}
