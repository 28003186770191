/* eslint-disable react/require-default-props */
import { Card, Col, Descriptions, Flex, Typography } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { CheckCircle, Warning } from '@phosphor-icons/react'
import dayjs from 'dayjs'

import type { Subscription } from '../types'
import { DescriptionsItemType } from 'antd/es/descriptions'

interface SubscriptionCardsProps {
    subscription: Subscription
    seatCount?: number
    usedSeats?: number
    onEditSubscription: (subscription: Subscription) => void
    onDeleteSubscription: (id: string) => void
}

export const SubscriptionCard = ({
    subscription,
    seatCount,
    usedSeats,
    onEditSubscription,
    onDeleteSubscription
}: SubscriptionCardsProps) => {
    const productKeys = subscription?.subscriptionProducts?.map(sp => sp.product.key).join(', ')

    const isExpired = dayjs(subscription?.endDate).isBefore(dayjs())
    const isActive = dayjs(subscription.startDate).isBefore(dayjs()) && !isExpired
    const isUpcoming = dayjs(subscription.startDate).isAfter(dayjs()) && !isExpired

    const subscriptionDetails: DescriptionsItemType[] = [
        {
            label: 'Status',
            children: subscription.status
        },
        {
            label: 'Term',
            children: subscription.term
        },
        {
            label: 'Products',
            children: productKeys
        },
        {
            label: 'Starts on',
            children: subscription?.startDate ? dayjs(subscription.startDate).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
        },
        {
            label: 'Ends on',
            children: subscription?.endDate ? dayjs(subscription.endDate).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
        }
    ]

    if (seatCount) {
        subscriptionDetails.push({
            label: 'Seat Count',
            children:
                seatCount && (usedSeats === 0 || (usedSeats || 0) > seatCount) ? (
                    <Flex gap="small" align="center">
                        <Typography.Text style={{ color: 'red' }}>{usedSeats}</Typography.Text>
                        <Typography.Text>/ {seatCount}</Typography.Text>
                    </Flex>
                ) : (
                    `${usedSeats} / ${seatCount}`
                )
        })
    }
    return (
        <Col key={subscription.id}>
            <Card
                style={{ width: 300 }}
                actions={[
                    <EditOutlined key="edit" onClick={() => onEditSubscription(subscription)} />,
                    <DeleteOutlined key="delete" onClick={() => onDeleteSubscription(subscription.id)} />
                ]}
            >
                <Descriptions
                    title={
                        <Flex gap="small" align="center" justify="space-between">
                            <Typography.Text>{productKeys}</Typography.Text>
                            {isExpired && (
                                <Flex align="center" gap="small">
                                    <Warning color="red" />
                                    <Typography.Text style={{ color: 'red' }}>Expired</Typography.Text>
                                </Flex>
                            )}
                            {isUpcoming && (
                                <Flex align="center" gap="small">
                                    <Warning color="orange" />
                                    <Typography.Text style={{ color: 'orange' }}>Upcoming</Typography.Text>
                                </Flex>
                            )}
                            {isActive && (
                                <Flex align="center" gap="small">
                                    <CheckCircle color="green" />
                                    <Typography.Text style={{ color: 'green' }}>Active</Typography.Text>
                                </Flex>
                            )}
                        </Flex>
                    }
                    items={subscriptionDetails}
                    column={1}
                />
            </Card>
        </Col>
    )
}
