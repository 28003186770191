import React, { forwardRef, useEffect, useRef } from 'react'
import Quill from 'quill'
import { type Delta } from 'quill/core'

// Quill CSS bundles
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'

// https://quilljs.com/docs/api#events

interface QuillEditorProps {
    readOnly?: boolean
    onTextChange?: (delta: Delta, oldContents: Delta, source: string) => void
    onSelectionChange?: (
        range: { index: number; length: number },
        oldRange: { index: number; length: number },
        source: string
    ) => void
    onEditorChange?: (name: string, ...args: any[]) => void
    defaultValue: any
    placeholder?: string
    theme?: 'bubble' | 'snow'
    toolbar?: any
    formats?: any
}

// Editor is an uncontrolled React component
export const QuillEditor = forwardRef(
    (
        {
            readOnly,
            defaultValue,
            onTextChange,
            onSelectionChange = undefined,
            onEditorChange = undefined,
            toolbar = undefined,
            formats = undefined,
            theme,
            placeholder
        }: QuillEditorProps,
        ref
    ) => {
        if (ref === null) {
            throw new Error('Implementation Error, please pass a ref to the Quill Editor')
        }
        const containerRef = useRef(null)

        useEffect(() => {
            if (ref) {
                // @ts-ignore
                ref.current?.editor.enable(!readOnly)
            }
        }, [ref, readOnly])

        useEffect(() => {
            const container = containerRef.current! as HTMLDivElement
            const editorContainer = container.appendChild(container.ownerDocument.createElement('div'))

            const options = {
                theme,
                placeholder,
                modules: {
                    toolbar
                },
                formats
            }

            const quill = new Quill(editorContainer, options)

            if (ref) {
                // @ts-ignore
                ref.current = quill
            }

            if (defaultValue) {
                if (typeof defaultValue === 'string') {
                    quill.clipboard.dangerouslyPasteHTML(defaultValue)
                } else {
                    quill.setContents(defaultValue)
                }
            }

            if (onTextChange) {
                quill.on(Quill.events.TEXT_CHANGE, (...args) => {
                    onTextChange(...args)
                })
            }

            if (onSelectionChange) {
                quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
                    onSelectionChange(...args)
                })
            }

            if (onEditorChange) {
                quill.on(Quill.events.EDITOR_CHANGE, (...args) => {
                    // @ts-ignore - not sure how to fix this one
                    onEditorChange(...args)
                })
            }

            return () => {
                if (ref) {
                    // @ts-ignore
                    ref.current = null
                }
                container.innerHTML = ''
            }
        }, [containerRef])

        return <div ref={containerRef} />
    }
)

QuillEditor.defaultProps = {
    readOnly: false,
    placeholder: undefined,
    onTextChange: undefined,
    onSelectionChange: undefined,
    onEditorChange: undefined,
    theme: 'snow',
    toolbar: undefined,
    formats: undefined
}

QuillEditor.displayName = 'QuillEditor'
