import { Button, Flex, Table, type TableProps } from 'antd'
import { Trash } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'

import { useConfirmation } from '@store/confirmation'
import { useNotifications } from '@store/notifications'
import { PublisherService } from '@services/PublisherService'

import { Publisher, PublisherUser } from '../../types'

export const UserTable = ({ publisher, refetch }: { publisher: Publisher; refetch: () => Promise<void> }) => {
    const navigate = useNavigate()
    const confirm = useConfirmation()
    const { showNotification } = useNotifications()

    const handleRemove = async (userId: string) => {
        if (
            await confirm({
                title: 'Are you sure you want to remove this user?',
                confirmText: 'Remove'
            })
        ) {
            try {
                await PublisherService.removeUser(publisher.id, userId)
                showNotification({ title: 'User removed' })
                await refetch()
            } catch (err) {
                showNotification({ title: 'Error removing user', type: 'error' })
            }
        }
    }

    const columns: TableProps<PublisherUser>['columns'] = [
        {
            title: 'Name',
            key: 'name',
            render: (_, { user }) => <>{user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : ''}</>
        },
        {
            title: 'Email',
            key: 'email',
            render: (_, { user }) => <>{user.email}</>
        },
        {
            title: 'Publisher Role',
            dataIndex: 'role'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, { user }) => (
                <Flex gap="middle" align="center">
                    <Button
                        type="link"
                        onClick={() => {
                            navigate(`/users/${user.id}`)
                        }}
                    >
                        View
                    </Button>
                    <Trash size={20} color="red" onClick={() => handleRemove(user.id)} cursor="pointer" />
                </Flex>
            )
        }
    ]

    return (
        <Table
            columns={columns}
            dataSource={publisher.publisherUsers || []}
            rowKey="id"
            pagination={{ pageSize: 50 }}
        />
    )
}
