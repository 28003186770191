import * as Sentry from '@sentry/react'

export const initializeSentry = () =>
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        tracePropagationTargets: [import.meta.env.VITE_SENTRY_DOMAIN],
        integrations: [
            Sentry.browserTracingIntegration(),
            // Keep the Replay integration as before
            Sentry.replayIntegration(),
            // The following is all you need to enable canvas recording with Replay
            Sentry.replayCanvasIntegration({
                // Enabling the following will ensure your canvas elements are not forced
                // into `preserveDrawingBuffer`
                enableManualSnapshot: true
            })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: import.meta.env.VITE_NODE_ENV
    })
