import type Quill from 'quill'
import { useRef } from 'react'

import { QuillEditor } from './QuillEditor'

type InputQuillFieldProps = {
    value: string
    onChange: (t: string) => void
}

export const InputQuillField = ({ value, onChange }: InputQuillFieldProps) => {
    const quillRef = useRef()

    const dataTestId = 'quill-field'

    const onTextChange = () => {
        if (quillRef && quillRef.current) {
            const quill = quillRef.current as Quill

            const html = quill.getSemanticHTML()

            onChange(html)
        }
    }

    const toolbarOptions = [
        ['bold', 'italic', 'underline'], // toggled buttons
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'super' }], // superscript/subscript
        ['clean'] // remove formatting button
    ]

    const formats = ['script', 'bold', 'italic', 'underline', 'list']

    return (
        <QuillEditor
            ref={quillRef}
            data-testid={dataTestId}
            defaultValue={value}
            onTextChange={onTextChange}
            toolbar={toolbarOptions}
            formats={formats}
        />
    )
}
