import React, { useState } from 'react'
import {
    Button,
    Form,
    type FormProps,
    Input,
    Modal,
    type NotificationArgsProps,
    notification,
    Typography,
    Select,
    Flex
} from 'antd'

import { type AddUserParams, SchoolService } from '@services/SchoolService'
import type { SchoolUser, School } from '../../../../types'

type ModalProps = {
    school: School
    isOpen: boolean
    onClose: () => void
    refetch: () => Promise<void>
}

type FieldType = {
    role?: string
    email?: string
}

type NotificationPlacement = NotificationArgsProps['placement']
type NotificationType = 'success' | 'error'

export const AddSchoolUserModal: React.FC<ModalProps> = ({ isOpen, school, onClose, refetch }) => {
    const [api, contextHolder] = notification.useNotification()
    const [isLoading, setIsLoading] = useState(false)

    const openNotification = (
        placement: NotificationPlacement,
        type: NotificationType,
        message: string,
        description?: string
    ) => {
        api[type]({
            message,
            description,
            placement
        })
    }

    const onFinish: FormProps<FieldType>['onFinish'] = async values => {
        const body: AddUserParams = {
            email: values.email!,
            role: values.role!
        }

        try {
            setIsLoading(true)
            await SchoolService.addUser(school.id!, body)
            openNotification('top', 'success', 'User added!')
            await refetch()
            onClose()
        } catch (err: any) {
            openNotification('top', 'error', 'Adding user failed!', err.toString())
        } finally {
            setIsLoading(false)
        }
    }

    if (!school) {
        return null
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            {contextHolder}
            <Flex vertical gap="middle">
                <Typography.Title level={4}>Add User to {school.name}</Typography.Title>
                <Typography.Text>
                    If the user already exists, they will be added to the school, if not they will receive an invite.
                </Typography.Text>
                <Form
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    layout="horizontal"
                    disabled={false}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { required: true, message: 'Please enter an email' },
                            () => ({
                                validator(_, value) {
                                    // all users
                                    const schoolUserEmails = school.schoolUsers.map((su: SchoolUser) => su.user.email)

                                    if (schoolUserEmails.find((email: string) => email === value)) {
                                        return Promise.reject(new Error('User already exists in this school.'))
                                    }
                                    return Promise.resolve()
                                }
                            })
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Role" name="role">
                        <Select defaultValue="USER" allowClear={false}>
                            <Select.Option value="USER">User</Select.Option>
                            <Select.Option value="ADMIN">Admin</Select.Option>
                            <Select.Option value="PARENT">Parent</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Add User
                        </Button>
                    </Form.Item>
                </Form>
            </Flex>
        </Modal>
    )
}
