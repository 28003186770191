/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { type ReactNode } from 'react'
import { Collapse, type CollapseProps, Flex } from 'antd'

import { type ObjectiveCategoryWithSubs } from '@services/NationalCurriculumService'
import { useBuilder } from '../BuilderContext'
import { YearLevelSection } from './YearLevelSection'

const ActionLink = ({
    children,
    onClick
}: {
    children: ReactNode
    onClick: React.MouseEventHandler<HTMLSpanElement>
}) => (
    <span style={{ color: '#1677ff' }} onClick={onClick}>
        {children}
    </span>
)

export const AlternateCategorySection = ({
    objectiveCategories
}: {
    objectiveCategories: ObjectiveCategoryWithSubs[]
}) => {
    const { setControl, yearLevels } = useBuilder()

    const defaultActiveKey = objectiveCategories.length > 0 ? objectiveCategories[0].id : undefined

    const items: CollapseProps['items'] = objectiveCategories?.map(objectiveCategory => {
        return {
            key: objectiveCategory.id,
            label: (
                <Flex justify="space-between">
                    {objectiveCategory.name}
                    <Flex gap="20px">
                        <ActionLink
                            onClick={() => {
                                setControl({
                                    action: 'EDIT',
                                    id: objectiveCategory.id,
                                    resource: 'OBJECTIVE_CATEGORY'
                                })
                            }}
                        >
                            Edit
                        </ActionLink>
                        <ActionLink
                            onClick={() => {
                                setControl({
                                    action: 'ADD',
                                    id: objectiveCategory.id,
                                    resource: 'OBJECTIVE_CATEGORY'
                                })
                            }}
                        >
                            Add Sub Category
                        </ActionLink>
                        <ActionLink
                            onClick={() => {
                                setControl({
                                    action: 'ADD',
                                    id: objectiveCategory.id,
                                    resource: 'OBJECTIVE'
                                })
                            }}
                        >
                            Add Objective
                        </ActionLink>
                    </Flex>
                </Flex>
            ),
            children: (
                <>
                    {objectiveCategory.subCategories && (
                        <AlternateCategorySection
                            key={objectiveCategory.id}
                            objectiveCategories={objectiveCategory.subCategories}
                        />
                    )}

                    {yearLevels &&
                        yearLevels.map(yearLevel => (
                            <YearLevelSection
                                key={yearLevel.id}
                                yearLevel={yearLevel}
                                objectiveCategoryId={objectiveCategory.id}
                            />
                        ))}
                </>
            )
        }
    })

    return <Collapse items={items} defaultActiveKey={defaultActiveKey} />
}
