import { useEffect, useState } from 'react'
import { Button, Form, Input, Space, Select } from 'antd'

import { CurriculumService } from '@services/CurriculumService'

import type { Objective, YearLevel } from '../../../../types'

import { InputQuillField } from './InputQuillField'

type ObjectiveFormProps = {
    title: string
    initialValues?: Objective
    onFinish: (values: Objective) => void
    onCancel: () => void
    objectiveCategoryId: string
    curriculumId: string
}

export const ObjectiveForm = ({
    title,
    initialValues,
    onFinish,
    onCancel,
    objectiveCategoryId,
    curriculumId
}: ObjectiveFormProps) => {
    const [yearLevels, setYearLevels] = useState<YearLevel[] | undefined>()

    const fetchYearLevelList = async () => {
        const curriculum = await CurriculumService.get(curriculumId)

        if (curriculum.yearLevels) {
            setYearLevels(curriculum.yearLevels)
        }
    }

    useEffect(() => {
        fetchYearLevelList()
    }, [objectiveCategoryId])

    if (!yearLevels) {
        return null
    }

    return (
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} layout="horizontal" disabled={false} onFinish={onFinish}>
            <h3>{title}</h3>
            <Form.Item
                label="Description"
                name="description"
                initialValue={initialValues?.description}
                rules={[{ required: true, message: 'Please enter a description' }]}
            >
                {/* @ts-ignore pretty sure antd puts props in via Form.Item. Gotta ignore the props issue */}
                <InputQuillField />
            </Form.Item>

            <Form.Item
                label="Code"
                name="code"
                initialValue={initialValues?.code}
                rules={[{ required: true, message: 'Please enter a code' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item label="Year Level" name="yearLevelId">
                <Select defaultValue={initialValues?.yearLevelId} disabled={!yearLevels || yearLevels.length === 0}>
                    {yearLevels?.map(yl => (
                        <Select.Option key={yl.id} value={yl.id}>
                            {yl.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                label="Order"
                name="order"
                initialValue={initialValues?.order}
                rules={[{ required: true, message: 'Please enter a order' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Space>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

ObjectiveForm.defaultProps = {
    initialValues: undefined
}
