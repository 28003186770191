import React from 'react'
import { Layout, theme } from 'antd'

const { Header, Content } = Layout

interface UnauthenticatedProps {
    children: React.ReactNode
}

export const UnauthenticatedLayout: React.FC<UnauthenticatedProps> = ({ children }) => {
    const {
        token: { colorBgContainer, borderRadiusLG }
    } = theme.useToken()

    return (
        <Layout>
            <Header style={{ display: 'flex', alignItems: 'center', background: colorBgContainer }}>
                <h1>Pomegranate Admin</h1>
            </Header>

            <Content
                style={{
                    margin: '24px 16px',
                    padding: 24,
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG
                }}
            >
                {children}
            </Content>
        </Layout>
    )
}
