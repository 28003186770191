import { CurriculumService } from '@services/CurriculumService'
import { Select, Space } from 'antd'
import { useEffect, useState } from 'react'

import { CurriculumDocument } from './CurriculumDocument'
import { BuilderProvider, useBuilder } from './BuilderContext'
import { EditObjectiveCategoryModal } from './modals/EditObjectiveCategoryModal'
import { EditObjectiveModal } from './modals/EditObjectiveModal'
import { CreateObjectiveModal } from './modals/CreateObjectiveModal'
import { CreateObjectiveCategoryModal } from './modals/CreateObjectiveCategoryModal'

import type { Curriculum } from '../../../types'

export const WrappedBuilder = () => {
    const { control, clearControl, setSelectedCurriculumId, selectedCurriculumId, fetchObjectives } = useBuilder()
    const { id, action, resource } = control

    const [curriculums, setCurriculums] = useState<Curriculum[]>()

    const fetchCurriculum = async () => {
        const response = await CurriculumService.list()
        setCurriculums(response)

        if (response.length > 0) {
            setSelectedCurriculumId(response[0].id)
        }
    }
    useEffect(() => {
        fetchCurriculum()
    }, [])

    if (!selectedCurriculumId) {
        return null
    }

    return (
        <>
            <h1>National Curriculum</h1>
            <div>
                <Space wrap>
                    <p>Select a curriculum</p>
                    <Select
                        defaultValue={selectedCurriculumId}
                        style={{ minWidth: '200px' }}
                        onChange={cId => setSelectedCurriculumId(cId)}
                    >
                        {curriculums?.map(c => (
                            <Select.Option key={c.id} value={c.id}>
                                {c.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Space>
            </div>
            <CurriculumDocument />

            {resource === 'OBJECTIVE_CATEGORY' && action === 'ADD' && (
                <CreateObjectiveCategoryModal
                    isOpen={resource === 'OBJECTIVE_CATEGORY' && action === 'ADD'}
                    curriculumId={selectedCurriculumId}
                    parentId={id}
                    onClose={() => clearControl()}
                    onSuccess={fetchObjectives}
                />
            )}
            {resource === 'OBJECTIVE_CATEGORY' && action === 'EDIT' && id && (
                <EditObjectiveCategoryModal
                    selectedId={id}
                    parentId={id}
                    onClose={() => clearControl()}
                    onSuccess={fetchObjectives}
                />
            )}
            {resource === 'OBJECTIVE' && action === 'EDIT' && id && (
                <EditObjectiveModal
                    curriculumId={selectedCurriculumId}
                    selectedId={id}
                    onClose={() => clearControl()}
                    onSuccess={fetchObjectives}
                />
            )}
            {resource === 'OBJECTIVE' && action === 'ADD' && id && (
                <CreateObjectiveModal
                    isOpen={resource === 'OBJECTIVE' && action === 'ADD'}
                    curriculumId={selectedCurriculumId}
                    objectiveCategoryId={id}
                    onClose={() => clearControl()}
                    onSuccess={fetchObjectives}
                />
            )}
        </>
    )
}

export const BuilderPage = () => {
    return (
        <BuilderProvider>
            <WrappedBuilder />
        </BuilderProvider>
    )
}
