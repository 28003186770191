import { useEffect, useState } from 'react'
import { Button, Space, Table, type TableProps } from 'antd'

import { useConfirmation } from '@store/confirmation'
import { useNotifications } from '@store/notifications'
import { BundleService } from '@services/BundleService'

import { type Bundle } from '../../../types'

import { EditBundleModal } from './modals/EditBundleModal'
import { CreateBundleModal } from './modals/CreateBundleModal'
import { AssociateResourceModal } from './modals/AssociateResourceModal'

export const BundlesPage = () => {
    const [data, setData] = useState<Bundle[]>()
    const [selectedEditId, setSelectedEditId] = useState<string | null>(null)
    const [selectedAssociateId, setSelectedAssociateId] = useState<string | null>(null)
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const confirm = useConfirmation()
    const { showNotification } = useNotifications()

    const fetch = async () => {
        const results = await BundleService.list()
        setData(results)
    }

    useEffect(() => {
        fetch()
    }, [])

    const onDelete = async (id: string) => {
        if (
            await confirm({
                title: 'Discarding draft',
                description: 'Are you sure you want to remove this draft?',
                confirmType: 'danger'
            })
        ) {
            try {
                await BundleService.delete(id)
                showNotification({ title: 'Curriculum has been deleted successfully' })

                fetch()
            } catch (err: any) {
                showNotification({ title: 'Curriculum deletion failed', type: 'error', description: err.toString() })
            }
        }
    }

    const columns: TableProps<Bundle>['columns'] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={() => {
                            setSelectedEditId(record.id)
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        type="link"
                        onClick={() => {
                            setSelectedAssociateId(record.id)
                        }}
                    >
                        Associate
                    </Button>
                    <Button
                        type="link"
                        onClick={() => {
                            onDelete(record.id)
                        }}
                    >
                        Delete
                    </Button>
                </Space>
            )
        }
    ]

    return (
        <div>
            <h1>Bundles</h1>
            <Button
                type="link"
                onClick={() => {
                    setShowCreateModal(true)
                }}
            >
                Create Bundle
            </Button>

            <Table columns={columns} dataSource={data} rowKey={r => r.id} />
            {selectedEditId && (
                <EditBundleModal
                    selectedId={selectedEditId}
                    onClose={() => setSelectedEditId(null)}
                    onSuccess={fetch}
                />
            )}
            {showCreateModal && (
                <CreateBundleModal
                    isOpen={showCreateModal}
                    onClose={() => setShowCreateModal(false)}
                    onSuccess={fetch}
                />
            )}
            {selectedAssociateId && (
                <AssociateResourceModal
                    selectedId={selectedAssociateId}
                    onClose={() => setSelectedAssociateId(null)}
                    onSuccess={fetch}
                />
            )}
        </div>
    )
}
