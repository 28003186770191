import { httpClient } from '@connectors/HttpClient'

import type { Objective, ObjectiveCategory, YearLevel, Curriculum } from '../types'

export type CurriculumParams = Omit<Curriculum, 'id'>

export type MappedObjectives = {
    [key: string]: Objective[]
}

export type ObjectiveCategoryWithSubs = {
    subCategories: ObjectiveCategoryWithSubs[]
    // objectives: Objective[]
} & ObjectiveCategory

export type NationalCurriculumReturn = {
    objectiveCategories: ObjectiveCategoryWithSubs[]
    mappedObjectives: MappedObjectives
    yearLevels: YearLevel[]
}

export const NationalCurriculumService = {
    get: async (id: string): Promise<NationalCurriculumReturn> => {
        const response = await httpClient.axios.get(`/curriculums/${id}`)
        return response.data
    }
}
