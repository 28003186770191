import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Form, type FormProps, Modal, Space } from 'antd'

import { useNotifications } from '@store/notifications'

import { BundleService, type BundleUpdateParams, type BundleWithFolders } from '@services/BundleService'

import type { CommunityResourceFolder } from '../../../../types'

type ModalProps = {
    selectedId: string | null
    onClose: () => void
    onSuccess: () => void
}

type BundleResourceFieldType = {
    communityResourceFolderIds: string[]
}

export const AssociateResourceModal: React.FC<ModalProps> = ({ selectedId, onClose, onSuccess }) => {
    const [bundle, setBundle] = useState<BundleWithFolders>()
    const [resourceFolders, setResourceFolders] = useState<CommunityResourceFolder[]>()
    const [checkboxOptions, setCheckboxOptions] = useState<{ label: string; value: string }[]>()
    const { showNotification } = useNotifications()

    useEffect(() => {
        const fetchBundle = async () => {
            try {
                const results = await BundleService.get(selectedId!)
                setBundle(results)
            } catch (err: any) {
                showNotification({ title: 'Could not retrieve bundle', type: 'error', description: err.toString() })
            }
        }

        const fetchResourceFolders = async () => {
            try {
                const results = await BundleService.listResourceFolders()

                const options = results.map(folder => {
                    return {
                        label: folder.name,
                        value: folder.id
                    }
                })
                setCheckboxOptions(options)
                setResourceFolders(results)
            } catch (err: any) {
                showNotification({ title: 'Could not retrieve bundle', type: 'error', description: err.toString() })
            }
        }

        if (selectedId) {
            fetchBundle()
            fetchResourceFolders()
        }
    }, [selectedId])

    const onFinish: FormProps<BundleResourceFieldType>['onFinish'] = async values => {
        const body: BundleUpdateParams = {
            bundleResources: values.communityResourceFolderIds.map(id => ({
                communityResourceFolderId: id
            }))
        }

        try {
            await BundleService.update(selectedId!, body)
            showNotification({ title: 'Bundle updated successfully' })
            onSuccess()
            onClose()
        } catch (err: any) {
            showNotification({ title: 'Bundle update failed', type: 'error', description: err.toString() })
        }
    }

    const isOpen = !!selectedId

    if (!bundle || !resourceFolders) {
        return null
    }

    const checkboxValues = bundle.bundleResources.map(bundleResource => bundleResource.communityResourceFolderId)

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                onFinish={onFinish}
            >
                <Form.Item label="Resource Folders" name="communityResourceFolderIds" initialValue={checkboxValues}>
                    <Checkbox.Group options={checkboxOptions} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    )
}
