import React, { useEffect, useState } from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '@connectors/Firebase'
import { useAuth } from '@hooks/useAuth'
import { useNavigate } from 'react-router-dom'

export const LoginPage: React.FC = () => {
    const [form] = Form.useForm()
    const [clientReady, setClientReady] = useState<boolean>(false)

    const { user, loaded } = useAuth()
    const navigate = useNavigate()

    // To disable submit button at the beginning.
    useEffect(() => {
        setClientReady(true)
    }, [])

    useEffect(() => {
        if (user && loaded) {
            navigate('/')
        }
    }, [user, loaded])

    const onFinish = async (values: any) => {
        try {
            await signInWithEmailAndPassword(auth, values.username, values.password)
        } catch (err: any) {
            // Do nothing for now
        }
    }

    if (!loaded) {
        return <>BLAH LOADING</>
    }

    return (
        <>
            <h2>Login</h2>
            <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
                <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                                !clientReady ||
                                !form.isFieldsTouched(true) ||
                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                            }
                        >
                            Log in
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </>
    )
}
