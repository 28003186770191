import { useEffect, useState } from 'react'
import { Button, Space, Table, type TableProps } from 'antd'

import { useNotifications } from '@store/notifications'
import { useConfirmation } from '@store/confirmation'
import { YearLevelService } from '@services/YearLevelService'
import { CreateYearLevelModal } from './modals/CreateYearLevelModal'
import { EditYearLevelModal } from './modals/EditYearLevelModal'

interface DataType {
    name: string
    id: string
    createdAt: Date
}

export const YearLevelListPage = () => {
    const [data, setData] = useState()
    const [selectedEditId, setSelectedEditId] = useState<string | null>(null)
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const confirm = useConfirmation()
    const { showNotification } = useNotifications()

    const fetch = async () => {
        const results = await YearLevelService.list()
        setData(results)
    }

    useEffect(() => {
        fetch()
    }, [])

    const onDelete = async (id: string) => {
        if (
            await confirm({
                title: 'Discarding draft',
                description: 'Are you sure you want to remove this draft?',
                confirmType: 'danger'
            })
        ) {
            try {
                await YearLevelService.delete(id)
                showNotification({ title: 'Year level has been deleted successfully' })

                fetch()
            } catch (err: any) {
                showNotification({ title: 'Year level deletion failed', type: 'error', description: err.toString() })
            }
        }
    }

    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Curriculum',
            dataIndex: 'curriculum',
            render: curriculum => curriculum.name,
            key: 'curriculumId'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Short Name',
            dataIndex: 'shortName',
            key: 'shortName'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={() => {
                            setSelectedEditId(record.id)
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        type="link"
                        onClick={() => {
                            onDelete(record.id)
                        }}
                    >
                        Delete
                    </Button>
                </Space>
            )
        }
    ]

    return (
        <div>
            <h1>Year Level</h1>
            <Button
                type="link"
                onClick={() => {
                    setShowCreateModal(true)
                }}
            >
                Create Year Level
            </Button>

            <Table columns={columns} dataSource={data} rowKey={r => r.id} />
            {selectedEditId && (
                <EditYearLevelModal
                    selectedId={selectedEditId}
                    onClose={() => setSelectedEditId(null)}
                    onSuccess={fetch}
                />
            )}
            {showCreateModal && (
                <CreateYearLevelModal
                    isOpen={showCreateModal}
                    onClose={() => setShowCreateModal(false)}
                    onSuccess={fetch}
                />
            )}
        </div>
    )
}
