import { useConfirmationConfiguration } from '@store/confirmation'

import { Modal } from 'antd'

export const Confirmation = () => {
    const { title, description, confirmText, cancelText, confirmType, handleConfirm, handleCancel, isOpen } =
        useConfirmationConfiguration()

    return (
        <>
            <Modal
                zIndex={99999}
                title={title}
                open={isOpen}
                onOk={handleConfirm}
                onCancel={handleCancel}
                okText={confirmText}
                okType={confirmType}
                cancelText={cancelText}
            >
                <p>{description}</p>
            </Modal>
        </>
    )
}
