export const currency = [
    {
        label: 'Canadian Dollar',
        shorthand: 'CAD',
        currencyType: 'canadian-dollar'
    },
    {
        label: 'New Zealand Dollar',
        shorthand: 'NZD',
        currencyType: 'new-zealand-dollar'
    },
    {
        label: 'British Pound Sterling',
        shorthand: 'GBP',
        currencyType: 'british-pound-sterling'
    },
    {
        label: 'United States Dollar',
        shorthand: 'USD',
        currencyType: 'united-states-dollar'
    }
]
