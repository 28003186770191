import React, { useState } from 'react'
import { Button, Form, type FormProps, Input, Modal, Space, type NotificationArgsProps, notification } from 'antd'

import { SchoolService } from '@services/SchoolService'

import { School } from '../../types'

type ModalProps = {
    school: School
    isOpen: boolean
    onClose: () => void
    refetch: () => Promise<void>
}

type FieldType = {
    name?: string
    country?: string
    state?: string
    city?: string
    postalCode?: string
    address?: string
    phone?: string
    email?: string
}

type NotificationPlacement = NotificationArgsProps['placement']
type NotificationType = 'success' | 'error'

export const EditSchoolDetailsModal: React.FC<ModalProps> = ({ isOpen, school, onClose, refetch }) => {
    const [api, contextHolder] = notification.useNotification()
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()

    const openNotification = (
        placement: NotificationPlacement,
        type: NotificationType,
        message: string,
        description?: string
    ) => {
        api[type]({
            message,
            description,
            placement
        })
    }

    const onFinish: FormProps<FieldType>['onFinish'] = async values => {
        const body: any = {
            id: school.id,
            name: values.name!,
            country: values.country!,
            state: values.state,
            city: values.city,
            postalCode: values.postalCode,
            address: values.address,
            phone: values.phone,
            email: values.email
        }

        try {
            setIsLoading(true)
            await SchoolService.update(school.id!, body)
            openNotification('top', 'success', 'School updated!')
            await refetch()
            onClose()
            form.resetFields()
        } catch (err: any) {
            openNotification('top', 'error', 'School update failed!', err.toString())
        } finally {
            setIsLoading(false)
        }
    }

    if (!school) {
        return null
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            {contextHolder}
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                onFinish={onFinish}
            >
                <h3>Edit School</h3>
                <Form.Item label="School ID">
                    <span>{school.id}</span>
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="name"
                    initialValue={school.name}
                    rules={[{ required: true, message: 'Please enter a school name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    initialValue={school.email}
                    rules={[{ required: true, message: 'Please enter a school name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Country"
                    name="country"
                    initialValue={school.country}
                    rules={[{ required: true, message: 'Please enter a country' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="State" name="state" initialValue={school.state}>
                    <Input />
                </Form.Item>
                <Form.Item label="City" name="city" initialValue={school.city}>
                    <Input />
                </Form.Item>
                <Form.Item label="Postal Code" name="postalCode" initialValue={school.postalCode}>
                    <Input />
                </Form.Item>
                <Form.Item label="Address" name="address" initialValue={school.address}>
                    <Input />
                </Form.Item>
                <Form.Item label="Phone" name="phone" initialValue={school.phone}>
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Submit
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    )
}
