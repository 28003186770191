import React, { useEffect, useState } from 'react'
import { Button, Flex, Space, Spin, Table, Typography, type TableProps } from 'antd'
import { useNavigate } from 'react-router-dom'

import { ProductService } from '../../../services/ProductService'
import type { Product } from '../../../types'
import { CreateProductModal } from '../../components/CreateProductModal'

export const ProductDashboardPage = () => {
    const [products, setProducts] = useState<Product[]>()
    const [isLoading, setIsLoading] = useState(false)
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

    const list = async () => {
        setIsLoading(true)
        try {
            const results = await ProductService.list()
            setProducts(results)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        list()
    }, [])

    const navigate = useNavigate()

    const columns: TableProps<Product>['columns'] = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={() => {
                            navigate(`/products/${record.id}`)
                        }}
                    >
                        View
                    </Button>
                </Space>
            )
        }
    ]

    if (isLoading) return <Spin />

    return (
        <>
            <Flex justify="space-between" align="center">
                <Typography.Title>Products</Typography.Title>
                <Button type="primary" onClick={() => setIsCreateModalOpen(true)}>
                    + Create Product
                </Button>
            </Flex>
            <Table columns={columns} dataSource={products} rowKey="id" pagination={false} />
            <CreateProductModal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} refetch={list} />
            {/* </Space> */}
        </>
    )
}
