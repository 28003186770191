import { useEffect, useState } from 'react'
import { Button, Space, Table, type TableProps } from 'antd'

import { useConfirmation } from '@store/confirmation'
import { useNotifications } from '@store/notifications'
import { CurriculumService } from '@services/CurriculumService'

import type { Curriculum } from '../../../types'

import { CreateCurriculumModal } from './modals/CreateCurriculumModal'
import { EditCurriculumModal } from './modals/EditCurriculumModal'

export const CurriculumListPage = () => {
    const [data, setData] = useState<Curriculum[]>()
    const [selectedEditId, setSelectedEditId] = useState<string | null>(null)
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const confirm = useConfirmation()
    const { showNotification } = useNotifications()

    const fetch = async () => {
        const results = await CurriculumService.list()
        setData(results)
    }

    useEffect(() => {
        fetch()
    }, [])

    const onDelete = async (id: string) => {
        if (
            await confirm({
                title: 'Discarding draft',
                description: 'Are you sure you want to remove this draft?',
                confirmType: 'danger'
            })
        ) {
            try {
                await CurriculumService.delete(id)
                showNotification({ title: 'Curriculum has been deleted successfully' })

                fetch()
            } catch (err: any) {
                showNotification({ title: 'Curriculum deletion failed', type: 'error', description: err.toString() })
            }
        }
    }

    const columns: TableProps<Curriculum>['columns'] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Short Code',
            dataIndex: 'shortCode',
            key: 'shortCode'
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={() => {
                            setSelectedEditId(record.id)
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        type="link"
                        onClick={() => {
                            onDelete(record.id)
                        }}
                    >
                        Delete
                    </Button>
                </Space>
            )
        }
    ]

    return (
        <div>
            <h1>Curriculum</h1>
            <Button
                type="link"
                onClick={() => {
                    setShowCreateModal(true)
                }}
            >
                Create Curriculum
            </Button>

            <Table columns={columns} dataSource={data} rowKey={r => r.id} />
            {selectedEditId && (
                <EditCurriculumModal
                    selectedId={selectedEditId}
                    onClose={() => setSelectedEditId(null)}
                    onSuccess={fetch}
                />
            )}
            {showCreateModal && (
                <CreateCurriculumModal
                    isOpen={showCreateModal}
                    onClose={() => setShowCreateModal(false)}
                    onSuccess={fetch}
                />
            )}
        </div>
    )
}
