import React from 'react'
import { Link, useRouteError } from 'react-router-dom'
import { Layout, theme } from 'antd'

const { Header, Content } = Layout

// This is the last fallback route! Cant use any components  which use theme here
// this is beyond router scope and theme provider scope
export const ErrorPage = () => {
    const {
        token: { colorBgContainer }
    } = theme.useToken()

    const error = useRouteError()

    let errorText = 'Oops! Something went wrong.'

    const errorEvent = error as ErrorEvent

    if (errorEvent?.message) {
        errorText = errorEvent.message
    }

    return (
        <Layout>
            <Header style={{ display: 'flex', alignItems: 'center', background: colorBgContainer }}>
                <h1>Error 🤔</h1>
            </Header>

            <Content
                style={{
                    padding: 50,
                    minHeight: 280
                }}
            >
                <p>{errorText}</p>

                <p>
                    <Link to="/logout">Logout</Link>
                </p>
            </Content>
        </Layout>
    )
}
