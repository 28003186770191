import { Form, Input, Modal, Select } from 'antd'
import { PublisherService } from '@services/PublisherService'
import { useNotifications } from '@store/notifications'
import type { Publisher } from '../../types'

interface Props {
    isOpen: boolean
    publisher: Publisher
    onClose: () => void
    refetch: () => Promise<void>
}

export const AddPublisherUserModal = ({ isOpen, publisher, onClose, refetch }: Props) => {
    const [form] = Form.useForm()
    const { showNotification } = useNotifications()

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields()
            await PublisherService.addUser(publisher.id, values)
            showNotification({ title: 'User added successfully' })
            form.resetFields()
            onClose()
            refetch()
        } catch (error) {
            showNotification({ title: 'Error adding user', type: 'error' })
        }
    }

    return (
        <Modal title="Add User to Publisher" open={isOpen} onCancel={onClose} onOk={handleSubmit}>
            <Form form={form} layout="vertical">
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        { required: true, message: 'Please input email!' },
                        { type: 'email', message: 'Please enter a valid email!' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="role" label="Role" rules={[{ required: true, message: 'Please select a role!' }]}>
                    <Select
                        options={[
                            { label: 'Admin', value: 'ADMIN' },
                            { label: 'Editor', value: 'EDITOR' },
                            { label: 'Viewer', value: 'VIEWER' }
                        ]}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}
