import { httpClient } from '@connectors/HttpClient'

import { type YearLevel } from '../types'

export type YearLevelParams = Omit<YearLevel, 'id'>

export const YearLevelService = {
    list: async (): Promise<any> => {
        const response = await httpClient.axios.get('/admin/year-levels')
        return response.data
    },
    get: async (id: string): Promise<any> => {
        const response = await httpClient.axios.get(`/admin/year-levels/${id}`)
        return response.data
    },
    update: async (id: string, params: Partial<YearLevelParams>): Promise<any> => {
        const response = await httpClient.axios.put(`/admin/year-levels/${id}`, params)
        return response.data
    },
    create: async (params: YearLevelParams): Promise<any> => {
        const response = await httpClient.axios.post('/admin/year-levels', params)
        return response.data
    },
    delete: async (id: string): Promise<any> => {
        const response = await httpClient.axios.delete(`/admin/year-levels/${id}`)
        return response.data
    }
}
