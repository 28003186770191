import React, { useState, useEffect } from 'react'
import { Button, Flex, Space, Spin, Table, TableProps, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

import type { Publisher } from '../../../types'
import { PublisherService } from '../../../services/PublisherService'
import { AddPublisherModal } from '../../components/AddPublisherModal'

export const PublisherDashboardPage = () => {
    const [publishers, setPublishers] = useState<Publisher[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [isAddModalOpen, setIsAddModalOpen] = useState(false)
    const navigate = useNavigate()

    const fetchPublishers = async () => {
        setIsLoading(true)
        try {
            const data = await PublisherService.list()
            setPublishers(data)
        } catch (error) {
            console.error('Error fetching publishers:', error)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchPublishers()
    }, [])

    if (isLoading) {
        return <Spin />
    }

    const columns: TableProps<Publisher>['columns'] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (_, record) => record.creator?.name
        },
        {
            title: 'Users',
            dataIndex: 'publisherUsers',
            render: (_, record) => record.publisherUsers.length
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={() => {
                            navigate(`/publishers/${record.id}`)
                        }}
                    >
                        View
                    </Button>
                </Space>
            )
        }
    ]

    return (
        <Flex vertical gap="middle">
            <Flex justify="space-between" align="center">
                <Typography.Title>Publishers</Typography.Title>
                <Button type="primary" onClick={() => setIsAddModalOpen(true)}>
                    + Add Publisher
                </Button>
            </Flex>

            <Table columns={columns} dataSource={publishers} rowKey="id" pagination={false} />

            <AddPublisherModal
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                refetch={fetchPublishers}
            />
        </Flex>
    )
}
