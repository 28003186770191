import axios, { AxiosHeaders, type AxiosInstance, type InternalAxiosRequestConfig } from 'axios'

import { auth } from './Firebase'

const API_BASE_URL = import.meta.env.VITE_BASE_API_URL
const IGNORE_INTERCEPT = ['/auth/login', '/auth/signup']
class HttpClient {
    axios: AxiosInstance

    constructor() {
        this.axios = axios.create({ baseURL: API_BASE_URL })

        // Intercept every requests and add the token to the header
        this.axios.interceptors.request.use(
            async value => {
                try {
                    // Only for the API
                    if (value.baseURL !== API_BASE_URL) return await Promise.resolve(value)

                    // Ignore some requests
                    if (value.url && IGNORE_INTERCEPT.includes(value.url)) return await Promise.resolve(value)

                    const requestConfig: InternalAxiosRequestConfig<any> = {
                        ...value,
                        headers: new AxiosHeaders({
                            'Content-Type': 'application/json',
                            ...value.headers
                        })
                    }

                    const user = auth.currentUser
                    // If Authorization header is not specified, add it
                    if (user && !value.headers.get('Authorization')) {
                        try {
                            const token = await user.getIdToken()
                            requestConfig.headers.set('Authorization', `Bearer ${token}`)
                        } catch (e) {
                            // Signout if failing to get token
                            await auth.signOut()
                        }
                    }

                    return requestConfig
                } catch (e) {
                    return await Promise.reject(e)
                }
            },
            error => {
                Promise.reject(error)
            }
        )
    }
}
export const httpClient = new HttpClient()
