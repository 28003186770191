import { create } from 'zustand'

type ConfirmTypes = 'default' | 'primary' | 'dashed' | 'link' | 'text' | 'danger'

interface ConfirmationState {
    isOpen: boolean
    title?: string
    description?: string
    confirmText?: string
    confirmType?: ConfirmTypes
    cancelText?: string
    promise?: { resolve: (b: boolean) => void }

    actions: {
        confirm: (options: {
            title?: string
            description?: string
            confirmText?: string
            cancelText?: string
            confirmType?: ConfirmTypes
        }) => Promise<boolean>
        close: () => void
    }
}

const CONFIRM_TEXT = 'Confirm'
const CANCEL_TEXT = 'Cancel'

export const useConfirmationStore = create<ConfirmationState>(set => ({
    isOpen: false,
    title: undefined,
    description: undefined,
    confirmText: CONFIRM_TEXT,
    confirmType: 'default',
    cancelText: 'Cancel',
    promise: undefined,
    actions: {
        confirm: ({ title, description, confirmText, cancelText, confirmType }) => {
            set(() => ({
                isOpen: true,
                title,
                description,
                confirmText: confirmText || CONFIRM_TEXT,
                confirmType: confirmType || 'default',
                cancelText: cancelText || CANCEL_TEXT
            }))

            return new Promise(resolve => {
                set(() => ({ promise: { resolve } }))
            })
        },
        close: () =>
            set(() => ({
                isOpen: false,
                title: undefined,
                description: undefined,
                confirmText: CONFIRM_TEXT,
                cancelText: CANCEL_TEXT
            }))
    }
}))

export type NotificationType = 'success' | 'error' | 'warn'

export const useConfirmationConfiguration = () => {
    const {
        isOpen,
        title,
        description,
        confirmText,
        confirmType,
        cancelText,
        promise,
        actions: { confirm, close }
    } = useConfirmationStore(state => state)

    const handleConfirm = () => {
        promise?.resolve(true)
        close()
    }

    const handleCancel = () => {
        promise?.resolve(false)
        close()
    }

    return {
        isOpen,
        title,
        description,
        confirmText,
        cancelText,
        confirmType,
        handleConfirm,
        handleCancel,
        confirm,
        close
    }
}

export const useConfirmation = () => useConfirmationStore(state => state.actions.confirm)
