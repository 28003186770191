import { httpClient } from '@connectors/HttpClient'

import type { Product, ProductBundle } from '../types'

export type ProductParams = Partial<Product>

export const ProductService = {
    list: async (): Promise<Product[]> => {
        const response = await httpClient.axios.get('/admin/products')
        return response.data
    },
    get: async (id: string): Promise<Product> => {
        const response = await httpClient.axios.get(`/admin/products/${id}`)
        return response.data
    },
    update: async (id: string, params: ProductParams): Promise<Product> => {
        const response = await httpClient.axios.put(`/admin/products/${id}`, params)
        return response.data
    },
    create: async (params: ProductParams): Promise<Product> => {
        const response = await httpClient.axios.post(`/admin/products`, params)
        return response.data
    },
    addBundle: async (id: string, bundleId: string): Promise<ProductBundle> => {
        const response = await httpClient.axios.post(`/admin/products/${id}/bundles`, { bundleId })
        return response.data
    },
    removeBundle: async (id: string, bundleId: string): Promise<void> => {
        const response = await httpClient.axios.delete(`/admin/products/${id}/bundles/${bundleId}`)
        return response.data
    }
}
