import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
    Button,
    Card,
    Col,
    Descriptions,
    type DescriptionsProps,
    Divider,
    Flex,
    Row,
    Spin,
    Tabs,
    Typography
} from 'antd'
import { useParams } from 'react-router-dom'

import { SchoolService } from '@services/SchoolService'
import { ProductService } from '@services/ProductService'
import { SubscriptionCard } from '@components/SubscriptionCards'
import { useConfirmation } from '@store/confirmation'

import AddSubscriptionModal from './components/AddSubscriptionModal'
import { EditSubscriptionModal } from './components/EditSubscriptionModal'
import { EditSchoolDetailsModal } from '../../components/EditSchoolDetailsModal'
import { AddSchoolUserModal } from './components/AddSchoolUserModal'
import { UserTable } from './UserTable'
import type { Product, School, Subscription } from '../../../types'

export const SchoolDetailsPage = () => {
    const [school, setSchool] = useState<School>()
    const [products, setProducts] = useState<Product[]>([])
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false)
    const [isAddSubscriptionModalOpen, setIsAddSubscriptionModalOpen] = useState(false)
    const [subscriptionToEdit, setSubscriptionToEdit] = useState<Subscription | null>(null)
    const confirm = useConfirmation()

    const { id } = useParams()

    const fetch = async () => {
        try {
            const results = await SchoolService.get(id!)
            setSchool(results)
        } catch (err) {
            console.error('Error retrieving school information', err)
        }

        try {
            const response = await ProductService.list()
            setProducts(response)
        } catch (error) {
            console.error('Error fetching products:', error)
        }
    }

    useEffect(() => {
        if (id) {
            fetch()
        }
    }, [id])

    const onDeleteSubscription = async (subscriptionId: string) => {
        if (!school) {
            return
        }
        if (
            await confirm({
                title: 'Are you sure you want to delete this subscription?',
                confirmText: 'Delete'
            })
        ) {
            await SchoolService.deleteSubscription(school.id!, subscriptionId)
            fetch()
        }
    }

    const closeEditModal = () => {
        setIsEditModalOpen(false)
    }

    const closeAddUserModal = () => {
        setIsAddUserModalOpen(false)
    }

    const items: DescriptionsProps['items'] = [
        {
            key: '0',
            label: 'School ID',
            children: school?.id
        },
        {
            key: '1',
            label: 'Name',
            children: school?.name
        },
        {
            key: '2',
            label: 'Country',
            children: school?.country
        },
        {
            key: '3',
            label: 'State',
            children: school?.state || 'N/A'
        },
        {
            key: '4',
            label: 'City',
            children: school?.city || 'N/A'
        },
        {
            key: '5',
            label: 'Postal Code',
            children: school?.postalCode || 'N/A'
        },
        {
            key: '6',
            label: 'Address',
            children: school?.address || 'N/A'
        },
        {
            key: '7',
            label: 'Phone',
            children: school?.phone || 'N/A'
        },
        {
            key: '8',
            label: 'Email',
            children: school?.email || 'N/A'
        },
        {
            key: '9',
            label: 'Created At',
            children: school?.createdAt ? dayjs(school.createdAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
        },
        {
            key: '10',
            label: 'Updated At',
            children: school?.updatedAt ? dayjs(school.updatedAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
        }
    ]

    if (!school) {
        return <Spin />
    }

    return (
        <Flex gap="middle" vertical>
            <Typography.Title>{school?.name}</Typography.Title>
            <Tabs style={{ padding: '20px' }}>
                <Tabs.TabPane tab="School Details" key="details">
                    <Descriptions
                        title="Informations"
                        items={items}
                        column={3}
                        extra={<Button onClick={() => setIsEditModalOpen(true)}>Edit</Button>}
                    />
                    <Divider />

                    <Typography.Title level={4}>Subscriptions</Typography.Title>
                    <Row gutter={[16, 16]}>
                        {school.schoolSubscriptions.map(({ subscription }) => {
                            const schoolSubscription = school.schoolSubscriptions.find(
                                ss => ss.subscriptionId === subscription.id
                            )

                            const seatCount = schoolSubscription?.seatCount || 0
                            const usedSeats = schoolSubscription?.schoolSubscriptionSeats?.length || 0

                            return (
                                <Col key={subscription.id}>
                                    <SubscriptionCard
                                        subscription={subscription}
                                        seatCount={seatCount}
                                        usedSeats={usedSeats}
                                        onEditSubscription={() => setSubscriptionToEdit(subscription)}
                                        onDeleteSubscription={onDeleteSubscription}
                                    />
                                </Col>
                            )
                        })}
                        <Col>
                            <Card hoverable style={{ width: 300 }} onClick={() => setIsAddSubscriptionModalOpen(true)}>
                                <Typography.Text style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                    + New Subscription
                                </Typography.Text>
                            </Card>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Users" key="users">
                    <Flex justify="space-between" align="center">
                        <Typography.Title level={4}>School Users</Typography.Title>
                        <Button type="primary" onClick={() => setIsAddUserModalOpen(true)}>
                            + Add User
                        </Button>
                    </Flex>
                    <UserTable school={school} refetch={fetch} />
                </Tabs.TabPane>
            </Tabs>
            <EditSchoolDetailsModal isOpen={isEditModalOpen} school={school} onClose={closeEditModal} refetch={fetch} />
            <AddSchoolUserModal
                isOpen={isAddUserModalOpen}
                school={school}
                onClose={closeAddUserModal}
                refetch={fetch}
            />
            <AddSubscriptionModal
                isOpen={isAddSubscriptionModalOpen}
                school={school}
                products={products}
                onClose={() => setIsAddSubscriptionModalOpen(false)}
                refetch={fetch}
            />
            <EditSubscriptionModal
                isOpen={!!subscriptionToEdit}
                subscription={subscriptionToEdit}
                products={products}
                refetch={fetch}
                school={school}
                seatCount={
                    (subscriptionToEdit &&
                        school.schoolSubscriptions.find(ss => ss.subscriptionId === subscriptionToEdit?.id)
                            ?.seatCount) ||
                    0
                }
                onClose={() => setSubscriptionToEdit(null)}
            />
        </Flex>
    )
}
