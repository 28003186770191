import React, { useEffect, useState } from 'react'
import { type FormProps, Modal } from 'antd'

import { useNotifications } from '@store/notifications'

import { type YearLevelParams, YearLevelService } from '@services/YearLevelService'

import { type YearLevel } from '../../../../types'
import type { YearLevelFieldType } from '../components/YearLevelForm'
import { YearLevelForm } from '../components/YearLevelForm'

type ModalProps = {
    selectedId: string
    onClose: () => void
    onSuccess: () => void
}

export const EditYearLevelModal: React.FC<ModalProps> = ({ selectedId, onClose, onSuccess }) => {
    const [data, setData] = useState<YearLevel>()
    const { showNotification } = useNotifications()

    useEffect(() => {
        const fetch = async () => {
            try {
                const results = await YearLevelService.get(selectedId!)
                setData(results)
            } catch (err: any) {
                showNotification({ title: 'Could not retrieve curriculum', type: 'error', description: err.toString() })
            }
        }

        if (selectedId) {
            fetch()
        }
    }, [selectedId])

    const onFinish: FormProps<YearLevelFieldType>['onFinish'] = async values => {
        const body: YearLevelParams = {
            name: values.name,
            shortName: values.shortName,
            curriculumId: values.curriculumId
        }

        try {
            await YearLevelService.update(selectedId, body)
            showNotification({ title: 'Year level updated successfully' })
            onSuccess()
            onClose()
        } catch (err: any) {
            showNotification({ title: 'Year level update failed', type: 'error', description: err.toString() })
        }
    }

    const isOpen = !!selectedId

    if (!data) {
        return null
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            <YearLevelForm title="Edit Year Level" initialValues={data} onFinish={onFinish} onCancel={onClose} />
        </Modal>
    )
}
