import { Button, List } from 'antd'
import type { YearLevel } from '../../../../types'

import { useBuilder } from '../BuilderContext'

import { HtmlViewer } from './HTMLViewer'

export const YearLevelSection = ({
    yearLevel,
    objectiveCategoryId
}: {
    yearLevel: YearLevel
    objectiveCategoryId: string
}) => {
    const { mappedObjectives, setControl } = useBuilder()

    const key = `${yearLevel.id}_${objectiveCategoryId}`

    const hasObjectives = !!(mappedObjectives && mappedObjectives[key])
    if (!hasObjectives) {
        return null
    }

    return (
        <>
            {mappedObjectives && (
                <List
                    header={<strong>{yearLevel.name}</strong>}
                    dataSource={mappedObjectives[key]}
                    renderItem={item => (
                        <List.Item
                            actions={[
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setControl({
                                            action: 'EDIT',
                                            id: item.id,
                                            resource: 'OBJECTIVE'
                                        })
                                    }}
                                    key="list-loadmore-edit"
                                >
                                    edit {item.code}
                                </Button>
                            ]}
                        >
                            <HtmlViewer html={item.description} />
                        </List.Item>
                    )}
                />
            )}
        </>
    )
}
