import { Button, Form, Input, Space } from 'antd'

export type CurriculumFieldType = {
    name: string
    slug: string
    shortCode: string
}

type CurriculumFormProps = {
    title: string
    initialValues?: CurriculumFieldType
    onFinish: (values: CurriculumFieldType) => void
    onCancel: () => void
}

export const CurriculumForm = ({ title, initialValues, onFinish, onCancel }: CurriculumFormProps) => {
    return (
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} layout="horizontal" disabled={false} onFinish={onFinish}>
            <h3>{title}</h3>
            <Form.Item
                label="Name"
                name="name"
                initialValue={initialValues?.name}
                rules={[{ required: true, message: 'Please enter a name' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Slug"
                name="slug"
                initialValue={initialValues?.slug}
                rules={[{ required: true, message: 'Please enter a slug' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Short Code"
                name="shortCode"
                initialValue={initialValues?.shortCode}
                rules={[{ required: true, message: 'Please enter a short code' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Space>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

CurriculumForm.defaultProps = {
    initialValues: undefined
}
