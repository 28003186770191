import { type NotificationArgsProps } from 'antd'
import { create } from 'zustand'

type NotificationPlacement = NotificationArgsProps['placement']
export type NotificationType = 'success' | 'error' | 'info' | 'warning' | 'open'

interface NotificationState {
    key?: string
    isOpen: boolean
    title: string
    description: string
    type: NotificationType
    duration: number
    placement: NotificationPlacement
    actions: {
        showNotification: (options: {
            title: string
            description?: string
            placement?: NotificationPlacement
            type?: NotificationType
            duration?: number
        }) => void
        closeNotification: () => void
    }
}
export const useNotificationsStore = create<NotificationState>(set => ({
    key: undefined,
    isOpen: false,
    title: '',
    description: '',
    type: 'success',
    duration: 4.5,
    placement: 'topRight',
    actions: {
        showNotification: ({ title, description = '', type = 'success', placement = 'topRight', duration = 4.5 }) => {
            set(() => ({
                key: new Date().toString() + title + description,
                isOpen: true,
                title,
                description,
                type,
                duration,
                placement
            }))
        },
        closeNotification: () => set(() => ({ isOpen: false, message: '' }))
    }
}))

export const useNotifications = () =>
    useNotificationsStore(state => {
        const {
            key,
            isOpen,
            title,
            description,
            type,
            placement,
            duration,
            actions: { showNotification }
        } = state
        return {
            key,
            isOpen,
            title,
            description,
            placement,
            type,
            duration,
            showNotification
        }
    })
