import React, { useState } from 'react'
import { Button, Form, type FormProps, Input, InputNumber, Modal, Select, Space } from 'antd'

import { useNotifications } from '@store/notifications'
import { type UserParams, UserService } from '@services/UserService'
import { currency } from '@constants/money'

import { type User } from '../../types'

type ModalProps = {
    user: User
    isOpen: boolean
    onClose: () => void
    refetch: () => Promise<void>
}

type FieldType = {
    firstName?: string
    lastName?: string
    maxBoards?: number
    currencyType?: string
}

export const EditUserModal: React.FC<ModalProps> = ({ isOpen, user, onClose, refetch }) => {
    const { showNotification } = useNotifications()

    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()

    const onFinish: FormProps<FieldType>['onFinish'] = async values => {
        const body: UserParams = {
            firstName: values.firstName!,
            lastName: values.lastName!,
            meta: {
                maxBoards: values.maxBoards!,
                currencyType: values.currencyType
            }
        }

        try {
            setIsLoading(true)
            await UserService.update(user.id!, body)
            showNotification({ title: 'User updated successfully' })
            await refetch()
            onClose()
            form.resetFields()
        } catch (err: any) {
            showNotification({ title: 'User Updated Failed', type: 'error', description: err.toString() })
        } finally {
            setIsLoading(false)
        }
    }

    if (!user) {
        return null
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                onFinish={onFinish}
            >
                <h3>Edit User</h3>
                <Form.Item label="User ID">
                    <span>{user.id}</span>
                </Form.Item>

                <Form.Item
                    label="First Name"
                    name="firstName"
                    initialValue={user.firstName}
                    rules={[{ required: true, message: 'Please enter a first name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="lastName"
                    initialValue={user.lastName}
                    rules={[{ required: true, message: 'Please enter a last name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Email">
                    <Input disabled placeholder="Not implemented" />
                </Form.Item>
                <Form.Item
                    label="Max Boards"
                    name="maxBoards"
                    initialValue={user.meta.maxBoards}
                    rules={[{ required: true, message: 'Please enter a maximum board number' }]}
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item label="Select" name="currencyType" initialValue={user.meta.currencyType}>
                    <Select>
                        {currency.map(c => (
                            <Select.Option key={c.currencyType} value={c.currencyType}>
                                {c.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Submit
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    )
}
