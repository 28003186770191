import React, { useState } from 'react'
import { Button, Form, type FormProps, Input, Modal, Space, type NotificationArgsProps, notification } from 'antd'

import { SchoolService } from '@services/SchoolService'
import { useNavigate } from 'react-router-dom'

type ModalProps = {
    isOpen: boolean
    onClose: () => void
}

type NotificationPlacement = NotificationArgsProps['placement']
type NotificationType = 'success' | 'error'

type FieldType = {
    name: string
    country: string
    state?: string
    city?: string
    postalCode?: string
    address?: string
    phone?: string
    email?: string
}

export const CreateSchoolModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
    const [api, contextHolder] = notification.useNotification()
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()

    const openNotification = (
        placement: NotificationPlacement,
        type: NotificationType,
        message: string,
        description?: string
    ) => {
        api[type]({
            message,
            description,
            placement
        })
    }

    const navigate = useNavigate()

    const onFinish: FormProps<FieldType>['onFinish'] = async values => {
        try {
            setIsLoading(true)
            const school = await SchoolService.create(values)
            navigate(`/schools/${school.id}`)
            openNotification('top', 'success', 'School created!')
            onClose()
            form.resetFields()
        } catch (err: any) {
            openNotification('top', 'error', 'School creation failed!', err.toString())
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            {contextHolder}
            <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} layout="horizontal" onFinish={onFinish}>
                <h3>Create School</h3>
                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter a school name' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Country"
                    name="country"
                    rules={[{ required: true, message: 'Please enter a country' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="State" name="state">
                    <Input />
                </Form.Item>
                <Form.Item label="City" name="city">
                    <Input />
                </Form.Item>
                <Form.Item label="Zip Code" name="zipCode">
                    <Input />
                </Form.Item>
                <Form.Item label="Address" name="address">
                    <Input />
                </Form.Item>
                <Form.Item label="Phone" name="phone">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Create
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    )
}
