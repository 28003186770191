import React, { useContext, useEffect, useState } from 'react'

import type { MappedObjectives, ObjectiveCategoryWithSubs } from '@services/NationalCurriculumService'
import { NationalCurriculumService } from '@services/NationalCurriculumService'

import type { YearLevel } from '../../../types'

type ControlType = {
    id: string | null
    action: 'EDIT' | 'ADD' | null
    resource: 'OBJECTIVE' | 'OBJECTIVE_CATEGORY' | null
}

interface BuilderContextProps {
    control: ControlType
    setControl: (a: ControlType) => void
    clearControl: () => void
    selectedCurriculumId?: string
    setSelectedCurriculumId: (key: string) => void
    objectiveCategories?: ObjectiveCategoryWithSubs[]
    mappedObjectives?: MappedObjectives
    yearLevels?: YearLevel[]
    fetchObjectives: () => void
}

const BuilderContext = React.createContext<BuilderContextProps | undefined>(undefined)

interface IBuilderProviderProps {
    children: React.ReactNode
}

export const BuilderProvider: React.FC<IBuilderProviderProps> = ({ children }) => {
    const [control, setControl] = useState<ControlType>({ id: null, action: null, resource: null })
    const [selectedCurriculumId, setSelectedCurriculumId] = useState<string>()

    const [objectiveCategories, setObjectiveCategories] = useState<ObjectiveCategoryWithSubs[]>()
    const [mappedObjectives, setMappedObjectives] = useState<MappedObjectives>()
    const [yearLevels, setYearLevels] = useState<YearLevel[]>()

    const fetchObjectives = async () => {
        if (selectedCurriculumId) {
            const response = await NationalCurriculumService.get(selectedCurriculumId)

            setObjectiveCategories(response.objectiveCategories)
            setMappedObjectives(response.mappedObjectives)
            setYearLevels(response.yearLevels)
        }
    }

    useEffect(() => {
        fetchObjectives()
    }, [selectedCurriculumId])

    const clearControl = () => {
        setControl({
            id: null,
            action: null,
            resource: null
        })
    }

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const passed = {
        control,
        setControl,
        clearControl,
        selectedCurriculumId,
        setSelectedCurriculumId,
        objectiveCategories,
        mappedObjectives,
        yearLevels,
        fetchObjectives
    }

    return <BuilderContext.Provider value={passed}>{children}</BuilderContext.Provider>
}

export const useBuilder = () => {
    const passed = useContext(BuilderContext)

    if (!passed) {
        throw new Error('useLessonResource improperly setup')
    }

    const {
        control,
        setControl,
        clearControl,
        selectedCurriculumId,
        setSelectedCurriculumId,
        objectiveCategories,
        mappedObjectives,
        yearLevels,
        fetchObjectives
    } = passed

    return {
        control,
        setControl,
        clearControl,
        selectedCurriculumId,
        setSelectedCurriculumId,
        objectiveCategories,
        mappedObjectives,
        yearLevels,
        fetchObjectives
    }
}
