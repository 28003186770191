import { useEffect, useState } from 'react'
import { Button, Descriptions, type DescriptionsProps, Flex, Spin, Typography } from 'antd'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { useNotifications } from '@store/notifications'
import { PublisherService } from '@services/PublisherService'
import type { Publisher } from '../../../types'
import { UserTable } from '../../components/UserTable'
import { EditPublisherDetailsModal } from '../../components/EditPublisherModal'
import { AddPublisherUserModal } from '../../components/AddPublisherUserModal'

export const PublisherDetailsPage = () => {
    const [publisher, setPublisher] = useState<Publisher>()
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false)
    const { id } = useParams()

    const { showNotification } = useNotifications()

    const fetch = async () => {
        try {
            const result = await PublisherService.get(id!)
            setPublisher(result)
        } catch (err) {
            showNotification({ title: 'Error retrieving publisher information', type: 'error' })
        }
    }

    useEffect(() => {
        if (id) {
            fetch()
        }
    }, [id])

    const items: DescriptionsProps['items'] = [
        {
            key: '0',
            label: 'Publisher ID',
            children: publisher?.id
        },
        {
            key: '1',
            label: 'Name',
            children: publisher?.creator?.name
        },
        {
            key: '2',
            label: 'Country',
            children: publisher?.country || 'N/A'
        },
        {
            key: '3',
            label: 'State',
            children: publisher?.state || 'N/A'
        },
        {
            key: '4',
            label: 'City',
            children: publisher?.city || 'N/A'
        },
        {
            key: '5',
            label: 'Zip Code',
            children: publisher?.zipCode || 'N/A'
        },
        {
            key: '6',
            label: 'Address',
            children: publisher?.address || 'N/A'
        },
        {
            key: '7',
            label: 'Phone',
            children: publisher?.phone || 'N/A'
        },
        {
            key: '8',
            label: 'Email',
            children: publisher?.email || 'N/A'
        },
        {
            key: '9',
            label: 'Created At',
            children: publisher?.createdAt ? dayjs(publisher.createdAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
        }
    ]

    if (!publisher) {
        return <Spin />
    }

    return (
        <Flex gap="middle" vertical>
            <Typography.Title>{publisher?.creator?.name}</Typography.Title>

            <Descriptions
                title="Information"
                items={items}
                column={3}
                extra={<Button onClick={() => setIsEditModalOpen(true)}>Edit</Button>}
            />

            <Flex justify="space-between" align="center">
                <Typography.Title level={4}>Publisher Users</Typography.Title>
                <Button type="primary" onClick={() => setIsAddUserModalOpen(true)}>
                    + Add User
                </Button>
            </Flex>

            <UserTable publisher={publisher} refetch={fetch} />

            <EditPublisherDetailsModal
                isOpen={isEditModalOpen}
                publisher={publisher}
                onClose={() => setIsEditModalOpen(false)}
                refetch={fetch}
            />
            <AddPublisherUserModal
                isOpen={isAddUserModalOpen}
                publisher={publisher}
                onClose={() => setIsAddUserModalOpen(false)}
                refetch={fetch}
            />
        </Flex>
    )
}
