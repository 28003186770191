import { useEffect, useState } from 'react'
import { Button, Form, Input, Space, Select } from 'antd'

import { CurriculumService } from '@services/CurriculumService'

import { type Curriculum } from '../../../../types'

export type YearLevelFieldType = {
    name: string
    curriculumId: string
    shortName: string
}

type YearLevelFormProps = {
    title: string
    initialValues?: YearLevelFieldType
    onFinish: (values: YearLevelFieldType) => void
    onCancel: () => void
}

export const YearLevelForm = ({ title, initialValues, onFinish, onCancel }: YearLevelFormProps) => {
    const [curriculums, setCurriculums] = useState<Curriculum[] | undefined>()

    const fetch = async () => {
        const results = await CurriculumService.list()
        setCurriculums(results)
    }

    useEffect(() => {
        fetch()
    }, [])

    if (!curriculums) {
        return null
    }

    return (
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} layout="horizontal" disabled={false} onFinish={onFinish}>
            <h3>{title}</h3>
            <Form.Item
                label="Name"
                name="name"
                initialValue={initialValues?.name}
                rules={[{ required: true, message: 'Please enter a name' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Short Name"
                name="shortName"
                initialValue={initialValues?.shortName}
                rules={[{ required: true, message: 'Please enter a short name' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item label="Curriculum" name="curriculumId">
                <Select defaultValue={initialValues?.curriculumId}>
                    {curriculums.map(c => (
                        <Select.Option key={c.id} value={c.id}>
                            {c.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Space>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

YearLevelForm.defaultProps = {
    initialValues: undefined
}
