import { httpClient } from '@connectors/HttpClient'

import type { Bundle, BundleResource, CommunityResourceFolder } from '../types'

export type BundleCreateParams = Omit<Bundle, 'id'>
export type BundleUpdateParams = Partial<
    BundleCreateParams & {
        bundleResources: {
            communityResourceFolderId: string
        }[]
    }
>

export type BundleWithFolders = Bundle & {
    bundleResources: (BundleResource & {
        communityResourceFolder: CommunityResourceFolder
    })[]
}

export const BundleService = {
    list: async (): Promise<Bundle[]> => {
        const response = await httpClient.axios.get(`/admin/bundles`)
        return response.data
    },
    get: async (id: string): Promise<BundleWithFolders> => {
        const response = await httpClient.axios.get(`/admin/bundles/${id}`)
        return response.data
    },
    update: async (id: string, params: BundleUpdateParams): Promise<any> => {
        const response = await httpClient.axios.put(`/admin/bundles/${id}`, params)
        return response.data
    },
    create: async (params: BundleCreateParams): Promise<any> => {
        const response = await httpClient.axios.post('/admin/bundles', params)
        return response.data
    },
    delete: async (id: string): Promise<any> => {
        const response = await httpClient.axios.delete(`/admin/bundles/${id}`)
        return response.data
    },
    // TODO: just used for sample for now
    listResourceFolders: async (): Promise<CommunityResourceFolder[]> => {
        const response = await httpClient.axios.get(`/admin/resource-folders`)
        return response.data
    }
}
