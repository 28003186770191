import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Button, Flex, Input, Pagination, Space, Spin, Table, Tabs, Typography, type TableProps } from 'antd'

import { UserService } from '../../../services/UserService'
import { useNavigate } from 'react-router-dom'
import { User } from '../../../types'

export const UserDashboardPage = () => {
    const [users, setUsers] = useState<User[]>()
    const [value, setValue] = useState('')
    const [totalCount, setTotalCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [offset, setOffset] = useState(0)
    const limit = 50 // Set a constant limit per page

    const search = async ({
        searchOffset,
        searchValue
    }: // searchSortBy,
    // searchSortOrder
    {
        searchOffset: number
        searchValue: string
        // searchSortBy: string
        // searchSortOrder: string
    }) => {
        try {
            setIsLoading(true)
            const results = await UserService.search({
                offset: searchOffset,
                limit,
                searchValue,
                sortBy: 'lastActiveAt',
                sortOrder: 'desc'
            })
            setUsers(results.users)
            setTotalCount(results.totalCount)
        } catch (e) {
            console.error('Error retrieving users', e)
        } finally {
            setIsLoading(false)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        search({ searchOffset: offset, searchValue: value })
    }, [])

    const navigate = useNavigate()

    const columns: TableProps<User>['columns'] = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Name',
            key: 'name',
            render: (_, { firstName, lastName }) => <> {`${firstName || ''} ${lastName || ''}`}</>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Firebase ID',
            dataIndex: 'firebaseId',
            key: 'firebaseId'
        },
        {
            title: 'Last Active',
            key: 'lastActive',
            render: (_, { lastActiveAt }) => (
                <>{lastActiveAt ? dayjs(lastActiveAt).format('YYYY/MM/DD HH:mm:ss') : 'N/A'}</>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={() => {
                            navigate(`/users/${record.id}`)
                        }}
                    >
                        View
                    </Button>
                </Space>
            )
        }
    ]

    if (!users) return <Spin />

    return (
        <>
            <Typography.Title>Users</Typography.Title>
            <Tabs style={{ padding: '20px' }}>
                <Tabs.TabPane tab="User List" key="list">
                    <Flex gap="middle" vertical>
                        <Flex justify="space-between" align="center">
                            <Input.Search
                                placeholder="Search users..."
                                loading={isLoading}
                                enterButton
                                style={{ width: '50%' }}
                                value={value}
                                onChange={e => {
                                    setValue(e.target.value)
                                }}
                                onSearch={() => {
                                    search({
                                        searchOffset: 0,
                                        searchValue: value
                                        // searchSortBy: sortBy,
                                        // searchSortOrder: sortOrder
                                    })
                                }}
                            />

                            <Typography.Text style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                Total: {totalCount}
                            </Typography.Text>
                        </Flex>
                        <Table columns={columns} dataSource={users} rowKey="userId" pagination={false} />
                        <Pagination
                            current={Math.floor(offset / limit) + 1}
                            onChange={async page => {
                                const newOffset = (page - 1) * limit
                                setOffset(newOffset)
                                await search({
                                    searchOffset: newOffset,
                                    searchValue: value
                                    // searchSortBy: sortBy,
                                    // searchSortOrder: sortOrder
                                })
                            }}
                            total={totalCount}
                            pageSize={limit}
                        />
                    </Flex>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Stats" key="stats">
                    test
                </Tabs.TabPane>
            </Tabs>
            {/* </Space> */}
        </>
    )
}
