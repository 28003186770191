import { httpClient } from '@connectors/HttpClient'

import type { Subscription, School } from '../types'

export type CreateSchoolParams = Partial<School>

export type AddUserParams = {
    email: string
    role: string
}

export type AddSubscriptionParams = Partial<Subscription> & {
    productIds: string[]
}

export type AssignedSchoolUers = {
    add: { [schoolSubscriptionId: string]: string[] }
    remove: { [schoolSubscriptionId: string]: string[] }
}

export const SchoolService = {
    search: async ({
        offset,
        limit,
        searchValue = '',
        sortBy = 'createdAt',
        sortOrder = 'desc'
    }: {
        offset: number
        limit: number
        searchValue?: string
        sortBy?: string
        sortOrder?: string
    }): Promise<{
        totalCount: number
        schools: School[]
    }> => {
        const response = await httpClient.axios.get('/admin/schools', {
            params: {
                offset,
                limit,
                searchValue,
                sortBy,
                sortOrder
            }
        })
        return response.data
    },
    create: async (params: CreateSchoolParams): Promise<any> => {
        const response = await httpClient.axios.post('/admin/schools', params)
        return response.data
    },
    get: async (id: string): Promise<School> => {
        const response = await httpClient.axios.get(`/admin/schools/${id}`)
        return response.data
    },
    update: async (id: string, params: CreateSchoolParams): Promise<any> => {
        const response = await httpClient.axios.put(`/admin/schools/${id}`, params)
        return response.data
    },
    addUser: async (id: string, params: AddUserParams): Promise<any> => {
        const response = await httpClient.axios.post(`/admin/schools/${id}/users`, params)
        return response.data
    },
    addSubscription: async (id: string, params: AddSubscriptionParams): Promise<any> => {
        const response = await httpClient.axios.post(`/admin/schools/${id}/subscriptions`, params)
        return response.data
    },
    updateSubscription: async (id: string, subscriptionId: string, params: AddSubscriptionParams): Promise<any> => {
        const response = await httpClient.axios.put(`/admin/schools/${id}/subscriptions/${subscriptionId}`, params)
        return response.data
    },
    deleteSubscription: async (id: string, subscriptionId: string): Promise<any> => {
        const response = await httpClient.axios.delete(`/admin/schools/${id}/subscriptions/${subscriptionId}`)
        return response.data
    },
    assignUsers: async (id: string, params: AssignedSchoolUers): Promise<any> => {
        const response = await httpClient.axios.post(`/admin/schools/${id}/subscriptions/assign-users`, params)
        return response.data
    },
    removeUser: async (id: string, userId: string): Promise<any> => {
        const response = await httpClient.axios.delete(`/admin/schools/${id}/users/${userId}`)
        return response.data
    }
}
