import React, { useEffect } from 'react'
import { useAuth } from '@hooks/useAuth'
import { useNavigate } from 'react-router-dom'

async function sleep(ms: number): Promise<void> {
    return new Promise(resolve => {
        setTimeout(resolve, ms)
    })
}

export const LogoutPage: React.FC = () => {
    const navigate = useNavigate()

    const { logout } = useAuth()

    // To disable submit button at the beginning.
    useEffect(() => {
        const out = async () => {
            await logout()

            await sleep(100)

            navigate('/')
        }
        out()
    }, [])

    return (
        <>
            <h2>Logout</h2>
        </>
    )
}
