import { Button, Form, Input, Space } from 'antd'

export type BundleFieldType = {
    name: string
    description: string
}

type BundleFormProps = {
    title: string
    initialValues?: BundleFieldType
    onFinish: (values: BundleFieldType) => void
    onCancel: () => void
}

export const BundleForm = ({ title, initialValues, onFinish, onCancel }: BundleFormProps) => {
    return (
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} layout="horizontal" disabled={false} onFinish={onFinish}>
            <h3>{title}</h3>
            <Form.Item
                label="Name"
                name="name"
                initialValue={initialValues?.name}
                rules={[{ required: true, message: 'Please enter a name' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Description"
                name="description"
                initialValue={initialValues?.description}
                rules={[{ required: true, message: 'Please enter a description' }]}
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Space>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

BundleForm.defaultProps = {
    initialValues: undefined
}
