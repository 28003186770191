import { Button } from 'antd'

import { useBuilder } from './BuilderContext'
import { AlternateCategorySection } from './components/CategorySection'

export const CurriculumDocument = () => {
    const { setControl, objectiveCategories } = useBuilder()

    if (!objectiveCategories) {
        return null
    }

    return (
        <div>
            {objectiveCategories && objectiveCategories.length > 0 && (
                <AlternateCategorySection objectiveCategories={objectiveCategories} />
            )}

            <Button
                type="link"
                onClick={() => {
                    setControl({
                        action: 'ADD',
                        id: null,
                        resource: 'OBJECTIVE_CATEGORY'
                    })
                }}
            >
                Add Category
            </Button>
        </div>
    )
}
