import React, { useEffect, useState } from 'react'
import { type FormProps, Modal } from 'antd'

import { useNotifications } from '@store/notifications'

import { type ObjectiveCategoryParams, ObjectiveCategoryService } from '@services/ObjectiveCategoryService'

import { type ObjectiveCategory } from '../../../../types'
import { ObjectiveCategoryForm } from '../components/ObjectiveCategoryForm'

type ModalProps = {
    selectedId: string
    onClose: () => void
    onSuccess: () => void
    parentId: string
}

export const EditObjectiveCategoryModal: React.FC<ModalProps> = ({ selectedId, onClose, onSuccess, parentId }) => {
    const [data, setData] = useState<ObjectiveCategory>()
    const { showNotification } = useNotifications()

    useEffect(() => {
        const fetch = async () => {
            try {
                const results = await ObjectiveCategoryService.get(selectedId!)
                setData(results)
            } catch (err: any) {
                showNotification({ title: 'Could not retrieve curriculum', type: 'error', description: err.toString() })
            }
        }

        if (selectedId) {
            fetch()
        }
    }, [selectedId])

    const onFinish: FormProps<ObjectiveCategory>['onFinish'] = async values => {
        const body: Partial<ObjectiveCategoryParams> = {
            name: values.name,
            order: values.order
        }

        try {
            await ObjectiveCategoryService.update(selectedId, body)
            showNotification({ title: 'Objective category updated successfully' })
            onSuccess()
            onClose()
        } catch (err: any) {
            showNotification({ title: 'Objective category update failed', type: 'error', description: err.toString() })
        }
    }

    const isOpen = !!selectedId
    console.log('D', data)
    if (!data) {
        return null
    }

    console.log('hello', isOpen)

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            <ObjectiveCategoryForm
                title="Edit Objective Category"
                initialValues={data}
                onFinish={onFinish}
                onCancel={onClose}
                parentId={parentId}
            />
        </Modal>
    )
}
