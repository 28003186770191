import React from 'react'
import { Layout, theme } from 'antd'
import { Outlet } from 'react-router-dom'
import { useAuth } from '@hooks/useAuth'
import PageLoader from '@components/PageLoader'

import { NavigationMenu } from './NavigationMenu'

const { Sider, Content } = Layout

export const AuthenticatedLayout: React.FC = () => {
    const { user } = useAuth()

    const {
        token: { colorBgContainer, borderRadiusLG }
    } = theme.useToken()

    // Adding is loading here just so we don't forget it. Need a loading state for board list :)
    if (!user) {
        return <PageLoader />
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible>
                <NavigationMenu />
            </Sider>
            <Layout>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG
                    }}
                >
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    )
}
