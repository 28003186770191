import { Form, Input, Modal } from 'antd'
import { useEffect } from 'react'

import { PublisherService } from '@services/PublisherService'
import { useNotifications } from '@store/notifications'
import type { Publisher } from '../../types'

interface Props {
    isOpen: boolean
    publisher: Publisher
    onClose: () => void
    refetch: () => Promise<void>
}

export const EditPublisherDetailsModal = ({ isOpen, publisher, onClose, refetch }: Props) => {
    const [form] = Form.useForm()
    const { showNotification } = useNotifications()

    useEffect(() => {
        if (isOpen && publisher) {
            form.setFieldsValue({
                name: publisher.creator?.name,
                country: publisher.country,
                state: publisher.state,
                city: publisher.city,
                zipCode: publisher.zipCode,
                address: publisher.address,
                phone: publisher.phone,
                email: publisher.email
            })
        }
    }, [isOpen, publisher, form])

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields()
            await PublisherService.update(publisher.id, values)
            showNotification({ title: 'Publisher updated successfully' })
            onClose()
            refetch()
        } catch (error) {
            showNotification({ title: 'Error updating publisher', type: 'error' })
        }
    }

    return (
        <Modal title="Edit Publisher Details" open={isOpen} onCancel={onClose} onOk={handleSubmit}>
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Please input publisher name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="country"
                    label="Country"
                    rules={[{ required: true, message: 'Please input country!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="state" label="State">
                    <Input />
                </Form.Item>
                <Form.Item name="city" label="City">
                    <Input />
                </Form.Item>
                <Form.Item name="zipCode" label="Zip Code">
                    <Input />
                </Form.Item>
                <Form.Item name="address" label="Address">
                    <Input />
                </Form.Item>
                <Form.Item name="phone" label="Phone">
                    <Input />
                </Form.Item>
                <Form.Item name="email" label="Email">
                    <Input type="email" />
                </Form.Item>
            </Form>
        </Modal>
    )
}
