import React from 'react'
import { type FormProps, Modal } from 'antd'

import { useNotifications } from '@store/notifications'
import { type YearLevelParams, YearLevelService } from '@services/YearLevelService'

import { type YearLevelFieldType, YearLevelForm } from '../components/YearLevelForm'

type ModalProps = {
    isOpen: boolean
    onClose: () => void
    onSuccess: () => void
}

export const CreateYearLevelModal: React.FC<ModalProps> = ({ isOpen, onClose, onSuccess }) => {
    const { showNotification } = useNotifications()

    const onFinish: FormProps<YearLevelFieldType>['onFinish'] = async values => {
        const body: YearLevelParams = {
            name: values.name,
            shortName: values.shortName,
            curriculumId: values.curriculumId
        }

        try {
            await YearLevelService.create(body)
            showNotification({ title: 'Year level created successfully' })
            onSuccess()
            onClose()
        } catch (err: any) {
            showNotification({ title: 'Year level creation failed', type: 'error', description: err.toString() })
        }
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            <YearLevelForm title="Create New Year Level" onFinish={onFinish} onCancel={onClose} />
        </Modal>
    )
}
