import React from 'react'
import { type FormProps, Modal } from 'antd'

import { useNotifications } from '@store/notifications'
import { type ObjectiveCategoryParams, ObjectiveCategoryService } from '@services/ObjectiveCategoryService'

import { ObjectiveCategoryForm } from '../components/ObjectiveCategoryForm'
import type { ObjectiveCategory } from '../../../../types'

type ModalProps = {
    isOpen: boolean
    parentId: string | null
    curriculumId: string
    onClose: () => void
    onSuccess: () => void
}

export const CreateObjectiveCategoryModal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    onSuccess,
    parentId,
    curriculumId
}) => {
    const { showNotification } = useNotifications()

    const onFinish: FormProps<ObjectiveCategory>['onFinish'] = async values => {
        const body: ObjectiveCategoryParams = {
            name: values.name,
            curriculumId,
            parentId: parentId || undefined,
            order: values.order
        }

        try {
            await ObjectiveCategoryService.create(body)
            showNotification({ title: 'Objective category created successfully' })
            onSuccess()
            onClose()
        } catch (err: any) {
            showNotification({
                title: 'Objective category creation failed',
                type: 'error',
                description: err.toString()
            })
        }
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            <ObjectiveCategoryForm
                title="Create New Objective Category"
                onFinish={onFinish}
                onCancel={onClose}
                parentId={parentId}
            />
        </Modal>
    )
}
