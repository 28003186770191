import { useEffect, useState } from 'react'
import { Button, Form, Input, Space } from 'antd'

import { ObjectiveCategoryService } from '@services/ObjectiveCategoryService'

import type { ObjectiveCategory } from '../../../../types'

type ObjectiveCategoryFormProps = {
    title: string
    initialValues?: ObjectiveCategory
    onFinish: (values: ObjectiveCategory) => void
    onCancel: () => void
    parentId: string | null
}

export const ObjectiveCategoryForm = ({
    title,
    initialValues,
    onFinish,
    onCancel,
    parentId
}: ObjectiveCategoryFormProps) => {
    const [parent, setParent] = useState<ObjectiveCategory | undefined>()
    // const [objectiveCategories, setObjectiveCategories] = useState<ObjectiveCategoryWithData[] | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const fetchObjectiveCategoryParent = async (pId: string) => {
        setIsLoading(true)
        const response = await ObjectiveCategoryService.get(pId)

        setParent(response)
        setIsLoading(false)
    }

    useEffect(() => {
        if (parentId) {
            fetchObjectiveCategoryParent(parentId)
        }
    }, [parentId])

    if (isLoading || (parentId && !parent)) {
        return null
    }

    const parentName = parentId ? parent?.name : 'none'

    return (
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} layout="horizontal" disabled={false} onFinish={onFinish}>
            <h3>{title}</h3>
            <h4>Parent Category - {parentName}</h4>
            <Form.Item
                label="Name"
                name="name"
                initialValue={initialValues?.name}
                rules={[{ required: true, message: 'Please enter a name' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Order"
                name="order"
                initialValue={initialValues?.order}
                rules={[{ required: true, message: 'Please enter a order' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Space>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

ObjectiveCategoryForm.defaultProps = {
    initialValues: undefined
}
