import { httpClient } from '@connectors/HttpClient'

import type { Publisher } from '../types'

export type PublihserParams = Partial<Publisher>

export const PublisherService = {
    list: async () => {
        const response = await httpClient.axios.get('/admin/publishers')
        return response.data
    },

    get: async (id: string) => {
        const response = await httpClient.axios.get(`/admin/publishers/${id}`)
        return response.data
    },

    create: async (data: PublihserParams) => {
        const response = await httpClient.axios.post('/admin/publishers', data)
        return response.data
    },

    update: async (id: string, data: PublihserParams) => {
        const response = await httpClient.axios.put(`/admin/publishers/${id}`, data)
        return response.data
    },

    delete: async (id: string) => {
        const response = await httpClient.axios.delete(`/admin/publishers/${id}`)
        return response.data
    },

    addUser: async (publisherId: string, data: { email: string; role: string }) => {
        const response = await httpClient.axios.post(`/admin/publishers/${publisherId}/users`, data)
        return response.data
    },

    removeUser: async (publisherId: string, userId: string) => {
        const response = await httpClient.axios.delete(`/admin/publishers/${publisherId}/users/${userId}`)
        return response.data
    }
}
