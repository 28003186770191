import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Button, Flex, Input, Pagination, Space, Spin, Table, Tabs, Typography, type TableProps } from 'antd'
import { useNavigate } from 'react-router-dom'

import { SchoolService } from '../../../services/SchoolService'
import type { School } from '../../../types'
import { CreateSchoolModal } from '../../components/CreateSchoolModal'

export const SchoolDashboardPage = () => {
    const [schools, setSchools] = useState<School[]>()
    const [value, setValue] = useState('')
    const [totalCount, setTotalCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [offset, setOffset] = useState(0)

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const limit = 50 // Set a constant limit per page

    const search = async ({ searchOffset, searchValue }: { searchOffset: number; searchValue: string }) => {
        try {
            setIsLoading(true)
            const results = await SchoolService.search({
                offset: searchOffset,
                limit,
                searchValue
            })

            setSchools(results.schools)
            setTotalCount(results.totalCount)
        } catch (e) {
            console.error('Error retrieving schools', e)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        search({
            searchOffset: offset,
            searchValue: value
        })
    }, [])

    const navigate = useNavigate()

    const columns: TableProps<School>['columns'] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country'
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city'
        },
        {
            title: 'Postal Code',
            dataIndex: 'postalCode',
            key: 'postalCode'
        },
        {
            title: 'Created',
            key: 'created',
            dataIndex: 'createdAt',
            render: (_, { createdAt }) => <>{dayjs(createdAt).format('YYYY/MM/DD HH:mm:ss')} </>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={() => {
                            navigate(`/schools/${record.id}`)
                        }}
                    >
                        View
                    </Button>
                </Space>
            )
        }
    ]

    if (!schools) return <Spin />

    return (
        <>
            <Flex justify="space-between" align="center">
                <Typography.Title>Schools</Typography.Title>
                <Button type="primary" onClick={() => setIsCreateModalOpen(true)}>
                    + Create School
                </Button>
            </Flex>
            <Tabs style={{ padding: '20px' }}>
                <Tabs.TabPane tab="Schools List" key="list">
                    <Flex gap="middle" vertical>
                        <Flex justify="space-between" align="center">
                            <Input.Search
                                placeholder="Search schools..."
                                loading={isLoading}
                                enterButton
                                style={{ width: '50%' }}
                                value={value}
                                onChange={e => {
                                    setValue(e.target.value)
                                }}
                                onSearch={() => {
                                    search({
                                        searchOffset: 0,
                                        searchValue: value
                                    })
                                }}
                            />
                            <Typography.Text style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                Total: {totalCount}
                            </Typography.Text>
                        </Flex>
                        <Table columns={columns} dataSource={schools} rowKey="schoolId" pagination={false} />
                        <Pagination
                            current={Math.floor(offset / limit) + 1}
                            onChange={async page => {
                                const newOffset = (page - 1) * limit
                                setOffset(newOffset)
                                await search({
                                    searchOffset: newOffset,
                                    searchValue: value
                                })
                            }}
                            total={totalCount}
                            pageSize={limit}
                        />
                    </Flex>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Stats" key="stats">
                    test
                </Tabs.TabPane>
            </Tabs>
            <CreateSchoolModal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} />
        </>
    )
}
