import React, { createContext, useContext, useEffect, type PropsWithChildren, useMemo, useReducer } from 'react'
import { type User } from 'firebase/auth'
import { Navigate } from 'react-router-dom'

import { auth } from '@connectors/Firebase'
import { MyUserService } from '@services/UserService'

type AuthParams = {
    user: User | null
    loaded: boolean
    isAdmin: boolean
    logout: () => void
}

const AuthContext = createContext<AuthParams>({
    user: null,
    loaded: false,
    isAdmin: false,
    logout: () => {}
})

type UserState = {
    firebaseUser: User | null
    isAdmin: boolean
    loaded: boolean
}
const initialState = {
    firebaseUser: null,
    isAdmin: false,
    loaded: false
}

function reducer(state: UserState, action: any): UserState {
    switch (action.type) {
        case 'COMPLETE':
            return {
                firebaseUser: action.firebaseUser,
                isAdmin: action.isAdmin,
                loaded: true
            }
        case 'LOGOUT':
            return {
                firebaseUser: null,
                isAdmin: false,
                loaded: false
            }
        default:
            return state
    }
}

export const AuthProvider = ({ children }: PropsWithChildren) => {
    const [user, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
            if (firebaseUser) {
                const dbUser = await MyUserService.my()

                dispatch({ type: 'COMPLETE', firebaseUser, isAdmin: dbUser.role === 'ADMIN' })
            } else {
                dispatch({ type: 'COMPLETE', firebaseUser: null, isAdmin: false })
            }
        })

        return unsubscribe
    }, [])

    const logout = async () => {
        await auth.signOut()
        dispatch({ type: 'LOGOUT' })
    }

    const value = useMemo(() => {
        return { user: user.firebaseUser, isAdmin: user.isAdmin, loaded: user.loaded, logout }
    }, [user])

    // TODO: need to add loading here somewhat?
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}

export const ProtectedRoute = ({ children }: PropsWithChildren) => {
    const { user, isAdmin, loaded } = useAuth()

    // If there is a current user it will render the passed down component
    if (user && isAdmin) {
        return children
    }

    if (user && loaded && !isAdmin) {
        throw new Error('You do not have access to this site')
    }

    // TODO: Handle global loading here?
    if (!loaded) {
        return <></>
    }

    // Otherwise redirect to the login route
    return <Navigate to="/login" />
}
