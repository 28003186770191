import React from 'react'
import { type FormProps, Modal } from 'antd'

import { useNotifications } from '@store/notifications'
import { type ObjectiveParams, ObjectiveService } from '@services/ObjectiveService'

import { ObjectiveForm } from '../components/ObjectiveForm'
import { type Objective } from '../../../../types'

type ModalProps = {
    isOpen: boolean
    onClose: () => void
    onSuccess: () => void
    curriculumId: string
    objectiveCategoryId: string
}

export const CreateObjectiveModal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    onSuccess,
    objectiveCategoryId,
    curriculumId
}) => {
    const { showNotification } = useNotifications()

    const onFinish: FormProps<Objective>['onFinish'] = async values => {
        const body: ObjectiveParams = {
            description: values.description,
            objectiveCategoryId,
            yearLevelId: values.yearLevelId,
            code: values.code,
            order: values.order
        }

        try {
            await ObjectiveService.create(body)
            showNotification({ title: 'Objective created successfully' })
            onSuccess()
            onClose()
        } catch (err: any) {
            showNotification({
                title: 'Objective creation failed',
                type: 'error',
                description: err.toString()
            })
        }
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            <ObjectiveForm
                title="Create New Objective"
                onFinish={onFinish}
                onCancel={onClose}
                objectiveCategoryId={objectiveCategoryId}
                curriculumId={curriculumId}
            />
        </Modal>
    )
}
