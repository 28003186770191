import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Modal, Form, DatePicker, Select, Button } from 'antd'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'

import { Product, User, Subscription, subscriptionsStatuses, subscriptionTerms } from '../../../../types'
import { UserService } from '@services/UserService'

dayjs.extend(weekday)
dayjs.extend(localeData)

interface EditSubscriptionModalProps {
    isOpen: boolean
    onClose: () => void
    products: Product[]
    subscription: Subscription | null // Replace 'any' with the actual Subscription type
    user: User | null
    refetch: () => Promise<void>
}

export const EditSubscriptionModal: React.FC<EditSubscriptionModalProps> = ({
    isOpen,
    onClose,
    user,
    products,
    subscription,
    refetch
}) => {
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (subscription && user) {
            form.setFieldsValue({
                status: subscription.status,
                term: subscription.term,
                dateRange: [dayjs(subscription.startDate), dayjs(subscription.endDate)],
                productIds: subscription.subscriptionProducts.map((sp: any) => sp.product.id)
            })
        }
    }, [subscription, form])

    if (!subscription) {
        return null
    }

    const handleSubmit = async (values: any) => {
        if (!user) {
            return
        }
        const formattedValues = {
            startDate: values.dateRange[0].toISOString(),
            endDate: values.dateRange[1].toISOString(),
            userId: user.id,
            productIds: values.productIds || [],
            status: values.status,
            term: values.term
        }

        try {
            setIsLoading(true)
            await UserService.updateSubscription(user.id, subscription.id, formattedValues)
            onClose()
            await refetch()
        } catch (error) {
            console.error('Error updating subscription:', error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal title="Edit Subscription" open={isOpen} onCancel={onClose} footer={null}>
            <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Form.Item
                    name="status"
                    label="Status"
                    initialValue={subscription.status}
                    rules={[{ required: true, message: 'Please select a status' }]}
                >
                    <Select>
                        {Object.values(subscriptionsStatuses).map(status => (
                            <Select.Option key={status} value={status}>
                                {status}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="term"
                    label="Term"
                    initialValue={subscription.term}
                    rules={[{ required: true, message: 'Please select a term' }]}
                >
                    <Select>
                        {Object.values(subscriptionTerms).map(term => (
                            <Select.Option key={term} value={term}>
                                {term}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="dateRange"
                    label="Start & End Date"
                    initialValue={[dayjs(subscription.startDate), dayjs(subscription.endDate)]}
                    rules={[{ required: true, message: 'Please select a start and end date' }]}
                >
                    <DatePicker.RangePicker />
                </Form.Item>

                <Form.Item
                    name="productIds"
                    label="Products"
                    initialValue={subscription.subscriptionProducts.map(sp => sp.product.id)}
                >
                    <Select mode="multiple">
                        {products.map(product => (
                            <Select.Option key={product.id} value={product.id}>
                                {product.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
                        Update Subscription
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}
