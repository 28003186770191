import React from 'react'
import { type FormProps, Modal } from 'antd'

import { type CurriculumParams, CurriculumService } from '@services/CurriculumService'
import { useNotifications } from '@store/notifications'

import { type CurriculumFieldType, CurriculumForm } from '../components/CurriculumForm'

type ModalProps = {
    isOpen: boolean
    onClose: () => void
    onSuccess: () => void
}

export const CreateCurriculumModal: React.FC<ModalProps> = ({ isOpen, onClose, onSuccess }) => {
    const { showNotification } = useNotifications()

    const onFinish: FormProps<CurriculumFieldType>['onFinish'] = async values => {
        const body: CurriculumParams = {
            name: values.name,
            slug: values.slug,
            shortCode: values.shortCode
        }

        try {
            await CurriculumService.create(body)
            showNotification({ title: 'Curriculum created successfully' })
            onSuccess()
            onClose()
        } catch (err: any) {
            showNotification({ title: 'Curriculum created failed', type: 'error', description: err.toString() })
        }
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            <CurriculumForm title="Create New Curriculum" onFinish={onFinish} onCancel={onClose} />
        </Modal>
    )
}
